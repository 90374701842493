import { useEffect, useState } from "react";
import apiSolicitud from "../../../../services/Api/solicitud";

export default function useGetRegexValidationPhone() {
  const [regexValidationPhone, setRegexValidationPhone] = useState(null);

  useEffect(() => {
    getRegexValidationPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getRegexValidationPhone() {
    try {
      const response = await apiSolicitud.getRegexValidationPhone();
      if (response.data) setRegexValidationPhone(response.data);
      else setRegexValidationPhone(null);
    } catch (err) {
      console.log(err);
      setRegexValidationPhone(null);
    }
  }

  return regexValidationPhone;
}
