import React from "react";
import { Box } from "@mui/material";
import HappyFace from "./HappyFace";
import SadFace from "./SadFace";
import ThinkingFace from "./ThinkingFace";
import ExpectationFace from "./ExpectationFace";
import VeryHappyFace from "./VeryHappyFace";
import FRFace from "./FRFace";

function Reactions() {
  return (
    <Box>
      <HappyFace />
      <SadFace />
      <ThinkingFace />
      <ExpectationFace />
      <VeryHappyFace />
      <FRFace />
    </Box>
  );
}

export default Reactions;
