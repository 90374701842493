import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const StepOver = ({ stepTitle, activeStep, maxStep }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      gap={2}
    >
      <Box
        display="flex"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            letterSpacing: "-0.5px !important",
          }}
          fontWeight="700"
        >
          {stepTitle}
        </Typography>
        <Typography
          fontSize="0.9rem"
          color="#4f4f4f"
          sx={{
            display: {
              xs: "none",
              sm: "block",
              md: "block",
              textTransform: "uppercase",
              letterSpacing: "-1px !important",
            },
          }}
        >
          {/* Paso {activeStep}/{maxStep} */}
        </Typography>
      </Box>
      <Divider sx={{ width: "100%" }} />
      {/* <LinearProgress
        variant="determinate"
        value={((activeStep) / maxStep) * 100}
        sx={{ width: "100%" }}
      /> */}
    </Box>
  );
};

export default StepOver;
