import React from "react";
import { Alert } from "@mui/material";
function Error({ openError, setOpenError }) {
  return (
    <div>
      {openError && (
        <Alert
          onClose={() => setOpenError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error al validar
        </Alert>
      )}
    </div>
  );
}

export default Error;
