import moment from "moment";
import listErrors from "./errores";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function currencyFormat(num) {
  return `${Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1?")
    .replaceAll(".", ",")
    .replaceAll("?", ".")}`;
}

function clearCedula(doc) {
  if (validateEmail(doc)) {
    return doc;
  }
  return doc.replaceAll(".", "").replaceAll("-", "").replaceAll(" ", "");
}
function sucursalFormat(id, label) {
  return `${id}. ${label}`;
}
const tipoCedulaFormat = (cedula, tipo) => {
  let cedulaConGuion = "";
  if (tipo === "3" || tipo === 3) {
    cedulaConGuion = `${clearCedula(cedula).slice(0, -1)}-${clearCedula(
      cedula
    ).slice(-1)}`;
    return cedulaConGuion;
  }
  return cedula;
};

const errorSetter = (setOpenAlert, setErrorText, error) => {
  setOpenAlert(true);
  let err = "";
  try {
    const { returnCode } = error.response.data;
    const { data } = error.response.data;

    try {
      err = listErrors.find(
        (item) => item.CompleteErrorCode === error.response.data
      ).CompleteMessage;
      return setErrorText(err);
    } catch (error) {}

    if (returnCode) {
      err = listErrors.find(
        (item) => item.CompleteErrorCode === error.response.data.returnCode
      ).CompleteMessage;
    } else if (data && data.returnCode) {
      err = listErrors.find(
        (item) => item.CompleteErrorCode === error.response.data.data.returnCode
      ).CompleteMessage;
    } else if (data) {
      err = listErrors.find(
        (item) => item.CompleteErrorCode === error.response.data.data
      ).CompleteMessage;
    } else if (data && data.explanation) {
      if (data.explanation.includes("[Ljava.lang")) {
        err = "Contactese con el administrador";
      } else {
        err = "Ha ocurrido un error";
      }
    } else {
      err = "Ha ocurrido un error";
    }
  } catch (e) {
    err = "Ha ocurrido un error";
    console.log(e);
  }
  return setErrorText(err);
};

const getDataForRefiAmpli = (dataset) => {
  return {
    montoHeredado: dataset.montoHeredado,
    discountPercentage: dataset.discountPercentage,
    valeHeredadoId: dataset.valeHeredadoId,
  };
};

const sumWithoutDecimals = (first, second) => {
  const sum = first + second;

  return sum.toFixed(2).replaceAll(".", ",");
};

const setErrorTitleAndBody = (error, specialTitle) => {
  const text = specialTitle ? specialTitle : "Ha ocurrido un error";
  if (!error || !error.data) {
    return {
      errorTitle: "Ha ocurrido un error",
      errorBody: "Contactese con el administrador",
    };
  }
  return {
    errorTitle: error.response.data.data
      ? error.response.data.data.returnCode
        ? listErrors.some(
            (item) =>
              item.CompleteErrorCode === error.response.data.data.returnCode
          )
          ? listErrors.find(
              (item) =>
                item.CompleteErrorCode === error.response.data.data.returnCode
            ).UncompleteMessage
          : text
        : text
      : text,
    errorBody: error.response.data.data
      ? error.response.data.data.returnCode
        ? listErrors.some(
            (item) =>
              item.CompleteErrorCode === error.response.data.data.returnCode
          )
          ? listErrors.find(
              (item) =>
                item.CompleteErrorCode === error.response.data.data.returnCode
            ).CompleteMessage
          : "Ha ocurrido un error"
        : error.response.data.data.explanation
        ? error.response.data.data.explanation.includes("[Ljava.lang")
          ? "Contactese con el administrador"
          : "Ha ocurrido un error"
        : "Ha ocurrido un error"
      : "Ha ocurrido un error",
  };
};

const findSpecificError = (toFind, error) => {
  let err = false;
  try {
    const { returnCode } = error.response.data;
    const { data } = error.response.data;
    const dataRaw = error.response.data;

    if (returnCode) {
      err = error.response.data.returnCode.includes(toFind);
    } else if (data) {
      if (data.returnCode) {
        err = error.response.data.data.returnCode.includes(toFind);
      } else {
        err = error.response.data.data.includes(toFind);
      }
    } else if (dataRaw) {
      err = error.response.data.includes(toFind);
    } else {
      err = false;
    }
  } catch (e) {
    err = false;
    console.log(e);
  }
  return err;
};
function capitalize(string) {
  const array = string.split(" ");
  const capitalizeArray = [];
  array.forEach((e) =>
    capitalizeArray.push(e.charAt(0).toUpperCase() + e.toLowerCase().slice(1))
  );
  return capitalizeArray.join(" ");
}

const showEmotions = (sucursal) => {
  return true;
};

const isSucursalWeb = (sucursal) => {
  if (Number(sucursal) === 3 || Number(sucursal) === 10) {
    return true;
  }
  return false;
};

const getSubstractYearsToToday = (years) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);
  return date;
};

const deleteCookie = function (name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

function handleDate(date) {
  let res = date;
  if (res) res = moment(date).utc().format("YYYY-MM-DD");
  if (res === "Invalid date") res = "";
  if (!res) res = "";

  return res;
}

export {
  findSpecificError,
  currencyFormat,
  clearCedula,
  validateEmail,
  sucursalFormat,
  tipoCedulaFormat,
  setErrorTitleAndBody,
  errorSetter,
  capitalize,
  showEmotions,
  getSubstractYearsToToday,
  deleteCookie,
  getDataForRefiAmpli,
  handleDate,
  sumWithoutDecimals,
  isSucursalWeb,
};
