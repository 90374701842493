import axios from "axios";

const login = async (correoElectronico, password, sucursal) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + `/login`,
    data: {
      correoElectronico: correoElectronico,
      password: password,
      sucursal: { id: sucursal },
    },
  });
};

const getSucursales = async (username) => {
  return axios({
    method: "GET",
    url:
      process.env.REACT_APP_API_URL +
      `/sucursales/usuario/username/all?username=${username}`,
  });
};

const getByUsername = async (username, token) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + `/usuarios/get-by-username`,
    data: {
      correoElectronico: username,
    },
    headers: { Authorization: token },
  });
};
export { login, getSucursales, getByUsername };
