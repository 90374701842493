import { Box, Step, StepContent, Stepper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
// import { useSelector } from "react-redux";
import StepOver from "../StepOver/StepOver";
import StepThreeFR from "./StepFR/StepFR";
import "./StepThree.css";
import StepThreeA from "./StepThreeA";
import StepThreeB from "./StepThreeB";

const StepThree = ({
  handleMainNextStep,
  title,
  stepNumber,
  stepToJump,
  stepsToJump,
}) => {
  // const stepConf = useSelector((state) => state.config.step);
  const [activeStep, setActiveStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const stepThree = useRef(null);

  useEffect(() => {
    if (stepToJump && stepsToJump) {
      if (stepToJump === stepNumber) {
        setActiveStep(stepsToJump);
      }
    }
    // eslint-disable-next-line
  }, [stepToJump, stepsToJump]);

  useEffect(() => {
    setMaxStep(stepThree.current.children.length);
    if (stepThree.current.children.length === 0) {
      handleMainNextStep();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <StepOver
        stepTitle={title}
        activeStep={activeStep + 1}
        maxStep={maxStep}
      />
      <Stepper
        ref={stepThree}
        activeStep={activeStep}
        orientation="vertical"
        connector={null}
      >
        {/* {stepConf.showMobileValidation && ( */}
        <Step>
          <StepContent>
            <StepThreeA
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        {/* )} */}
        <Step>
          <StepContent>
            <StepThreeFR
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        <Step>
          <StepContent>
            <StepThreeB
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
};

export default StepThree;
