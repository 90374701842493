import axios from "axios";

const baseStep = {
  id: null,
  sucursal: {
    id: 1,
  },
  step: "STEP_0",
  paisIso: "URY",
  tipoDocumento: 3,
  pais: {
    id: 633,
  },
  solicitudFromAsesor: window.location.hostname.includes("operador"),
  solicitudWithoutAsesor: !window.location.hostname.includes("operador"),
};

const stepOneA = async (documento, celular, canal, sucursal, token, ipData) => {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_ENDPOINT,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      documento: documento,
      celular: celular,
      canal,
      sucursal: { id: sucursal },
      zIndex: ipData,
    },
  });
};

const stepOneB = async (
  id,
  nombre,
  apellido,
  segundoNombre,
  segundoApellido,
  departamento,
  localidad,
  barrio,
  ingreso,
  genero,
  relacionLaboral,
  documento,
  celular,
  fechaNacimiento,
  email,
  canal,
  sucursal,
  token,
  refiAmpliData,
  nivelEstudioSimple,
  estadoCivil,
  tycGenerales
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_1",
      id,
      nombre: nombre,
      apellido: apellido,
      segundoNombre: segundoNombre,
      segundoApellido: segundoApellido,
      departamento: { nombre: departamento.label, id: departamento.id },
      localidad: { nombre: localidad.label, id: localidad.id },
      barrio: barrio ? { nombre: barrio.label, id: barrio.id } : barrio,
      ingresoDeclarado: ingreso,
      genero: genero,
      relacionLaboral: relacionLaboral,
      documento: documento,
      celular: celular,
      fechaNacimiento: fechaNacimiento,
      email: email,
      canal,
      nivelEstudioSimple,
      estadoCivil,
      sucursal: { id: sucursal },
      ...refiAmpliData,
      aceptaTyCGenerales: tycGenerales,
    },
  });
};

const stepFamily = async (id, cedula, telefono, canal, sucursal, token) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_PRODUCT_FAMILY_CHOOSE",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const stepSelectFamily = async (
  id,
  cedula,
  telefono,
  canal,
  sucursal,
  token,
  prodFinanFlia
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_PRODUCT_FAMILY_SELECTED",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
      prodFinanFlia: {
        id: prodFinanFlia,
      },
    },
  });
};

const stepTwoA = async (
  id,
  montoSolicitado,
  monedaExpresion,
  fechaPrimerCuota,
  cedula,
  telefono,
  canal,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_2",
      id,
      montoSolicMonedaExpresion: montoSolicitado,
      monedaExpresion: monedaExpresion,
      fechaPrimerCuota: fechaPrimerCuota,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};
const stepTwoA2 = async (
  id,
  destinoDinero,
  cedula,
  telefono,
  canal,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_MONEY_DESTINATION",
      id,
      destinoDinero: destinoDinero,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const stepTwoA3 = async (
  canal,
  id,
  plazoCuota,
  destinoDinero,
  cedula,
  telefono,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_3",
      id,
      plazoCuota: plazoCuota,
      destinoDinero: destinoDinero,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const stepConfirmacionIntermedia = async (
  canal,
  id,
  cedula,
  telefono,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_CONFIRMACION_INTERMEDIA",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const stepTyCIntermedio = async (
  canal,
  id,
  cedula,
  telefono,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_4",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const stepThreeA = async (
  canal,
  id,
  direccion,
  aceptaTyC,
  cedula,
  telefono,
  sucursal,
  token,
  tokenRemote
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_5",
      id,
      direccion: direccion,
      aceptaTyC: aceptaTyC,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
      tokenRemoteDigitalSignature: tokenRemote,
    },
  });
};

const stepThreeB = async (canal, id, cedula, telefono, sucursal, token) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_CONTINUE_OR_CONTACT_CENTER",
      id,
      documento: cedula,
      celular: telefono,
      opcion: "CONTINUAR_CONTACT_CENTER",
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const stepSelectAdditionals = async (
  canal,
  id,
  cedula,
  telefono,
  sucursal,
  token,
  adicionalesListSelected = []
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_SELECT_ADDITIONALS",
      id,
      documento: cedula,
      celular: telefono,
      opcion: "CONTINUAR_CONTACT_CENTER",
      canal,
      sucursal: { id: sucursal },
      productoFinancieroAdicionalLista: adicionalesListSelected,
    },
  });
};

const stepFourA = async (formData, token) => {
  return axios({
    method: "POST",
    url:
      process.env.REACT_APP_API_URL +
      `/fintech/solicitud/validateFaceRecognition`,
    headers: token
      ? { Authorization: token, "Content-Type": "multipart/form-data" }
      : { "Content-Type": "multipart/form-data" },
    data: formData,
  });
};

const stepGetQuestions = (id, sucursal, canal, cedula, telefono) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_ID_VALIDATOR",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const stepSendAnswers = (id, sucursal, canal, cedula, telefono, peticion) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_ID_VALIDATOR_CHECK",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
      peticion,
    },
  });
};

const stepFourB = async (
  id,
  cedula,
  mail,
  telefono,
  canal,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_MOBILE",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const stepFourBMail = async (
  id,
  cedula,
  mail,
  telefono,
  canal,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_MAIL",
      id,
      celular: telefono,
      documento: cedula,
      email: mail,
      canal,
      sucursal: { id: sucursal },
    },
  });
};
const stepFourC = async (
  id,
  cedula,
  email,
  telefono,
  smsCode,
  canal,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_MOBILE_CHECK",
      id,
      smsCode: smsCode,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};
const stepFourD = async (
  id,
  cedula,
  email,
  telefono,
  emailCode,
  canal,
  sucursal,
  token
) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_MAIL_CHECK",
      id,
      emailCode: emailCode,
      celular: telefono,
      documento: cedula,
      email: email,
      canal,
      sucursal: { id: sucursal },
    },
  });
};
const stepFive = async (id, cedula, telefono, canal, sucursal, token) => {
  return axios({
    method: "PATCH",
    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_UPDATE,
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_10",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const resetFr = async (id, cedula, telefono, canal, sucursal, token) => {
  return axios({
    method: "PATCH",
    url:
      process.env.REACT_APP_API_URL + "/fintech/solicitud/resetFaceRecognition",
    headers: token
      ? { Authorization: token }
      : { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_DOCUMENT_VALIDATION",
      id,
      documento: cedula,
      celular: telefono,
      canal,
      sucursal: { id: sucursal },
    },
  });
};

const queryPayment = async (cedula, telefono, canal) => {
  return axios({
    method: "POST",
    url:
      process.env.REACT_APP_API_URL + `/fintech/situacionInicial/debtPayment`,
    headers: { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_DEBT_PAYMENT_1",
      id: null,
      documento: cedula,
      celular: telefono,
      canal,
    },
  });
};

const cancelPayment = async (
  id,
  cedula,
  telefono,
  montoCancelado,
  comprobante,
  canal
) => {
  return axios({
    method: "POST",
    url:
      process.env.REACT_APP_API_URL + `/fintech/situacionInicial/debtPayment`,
    headers: { "Content-Type": "application/json" },
    data: {
      ...baseStep,
      step: "STEP_DEBT_PAYMENT_2",
      id,
      documento: cedula,
      celular: telefono,
      montoCancelado: montoCancelado,
      comprobante: comprobante,
      canal,
    },
  });
};

export {
  stepOneA,
  stepOneB,
  stepFamily,
  stepSelectFamily,
  stepTwoA,
  stepTwoA2,
  stepTwoA3,
  stepConfirmacionIntermedia,
  stepSelectAdditionals,
  stepTyCIntermedio,
  stepThreeA,
  stepThreeB,
  stepFourA,
  stepFourB,
  stepFourBMail,
  stepGetQuestions,
  stepSendAnswers,
  stepFourC,
  stepFourD,
  stepFive,
  resetFr,
  queryPayment,
  cancelPayment,
};
