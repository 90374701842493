import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import configReducer from "./configReducer/configReducer";
import prevRequestReducer from "./configReducer/prevRequestSlice";
import formTriesReducer from "./formTriesSlice";

export const store = configureStore({
  reducer: {
    config: configReducer,
    formTries: formTriesReducer,
    prevRequest: prevRequestReducer,
  },
});

const rootPersistConfig = {
  key: "root",
  storage: storage,
};

const rootReducer = combineReducers({
  config: configReducer,
  formTries: formTriesReducer,
  prevRequest: prevRequestReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
