import { Alert, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepFourB, stepFourBMail } from "../../../services/steps.services";
import { showEmotions, setErrorTitleAndBody } from "../../../utils";
import Validator from "../../Validators/Validators";

const StepThreeA = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [intentos, setIntentos] = useState(3);
  const dataset = useSelector((state) => state.config.dataset);
  const stepConf = useSelector((state) => state.config.step);
  const [smsValid, setSmsValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const { id, cedula, email, telefono, operador, sucursal, canal, token } =
    dataset;
  const stepMail =
    email &&
    !operador &&
    stepConf.showEmailValidation &&
    !dataset.emailOptional;

  const nextStepWithEmotion = () => {
    if (showEmotions(dataset.sucursal)) {
      dispatch({ type: "show/showFRFace" });
      setTimeout(() => {
        dispatch({ type: "hide/emotions" });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
      }, 5000);
    } else {
      if (activeStep === maxStep) {
        handleMainNextStep();
      } else {
        handleNextStep();
      }
    }
  };

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const onChange = (type, e) => {
    if (intentos === 0) {
      return setOpenAlert(true);
    }

    if (type === "SMS") {
      if (e) {
        setSmsValid(true);
      } else {
        setIntentos(intentos - 1);
      }
    }
    if (type === "Email") {
      if (e) {
        setEmailValid(true);
      } else {
        setIntentos(intentos - 1);
      }
    }
  };
  React.useEffect(() => {
    const sendCodes = async () => {
      dispatch({ type: "show/spinner" });
      try {
        if (stepMail) {
          await stepFourBMail(
            id,
            cedula,
            email,
            telefono,
            canal,
            sucursal,
            token
          );
        }
        if (stepConf.showMobileValidation) {
          await stepFourB(id, cedula, email, telefono, canal, sucursal, token);
        }
      } catch (error) {
        console.log(error);
        errorPop(error);
      }

      dispatch({ type: "hide/spinner" });
    };
    if (dataset.doneJumping) {
      sendCodes();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const checkValidated = () => {
      if (stepMail) {
        if (smsValid && emailValid) {
          handleSubmit();
        }
      } else {
        if (smsValid) {
          handleSubmit();
        }
      }
    };
    checkValidated();
    if (dataset.email !== "" || dataset.emailOptional) {
      if (!stepConf.showMobileValidation && !stepConf.showEmailValidation) {
        nextStepWithEmotion();
      }
    } else {
      if (!stepConf.showMobileValidation) {
        nextStepWithEmotion();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsValid, emailValid]);

  React.useEffect(() => {
    if (intentos === 0) {
      dispatch({
        type: "show/help",
        payload: {
          helpTitle: "Ha ocurrido un error",
          helpBody: "Un agente se contactara a la brevedad",
          showButton: false,
        },
      });
    }
    // eslint-disable-next-line
  }, [intentos]);

  const errorPop = (error) => {
    dispatch({
      type: "show/error",
      payload: setErrorTitleAndBody(error),
    });
  };

  const handleSubmit = () => {
    dispatch({ type: "hide/spinner" });
    nextStepWithEmotion();
  };

  if (dataset.email !== "") {
    if (!stepConf.showMobileValidation && !stepConf.showEmailValidation) {
      return <Box />;
    }
  } else {
    if (!stepConf.showMobileValidation) {
      return <Box />;
    }
  }

  return (
    <>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {intentos > 0
            ? `No hemos podido verificar tu identidad, tienes ${intentos} ${
                intentos > 1 ? "intentos" : "intento"
              } ${intentos > 1 ? "restantes" : "restante"}.`
            : "No hemos podido verificar tu identidad"}
        </Alert>
      </Snackbar>
      <Box
        // component="form"
        display="flex"
        flexDirection="column"
        gap={4}
      >
        {stepConf.showMobileValidation && (
          <Validator onChange={(e) => onChange("SMS", e)} type="SMS" />
        )}
        {stepMail && (
          <Validator onChange={(e) => onChange("Email", e)} type="Mail" />
        )}
        {/* <Error text={errorText} show={openError} setShow={setOpenError} /> */}
      </Box>
    </>
  );
};

export default StepThreeA;
