import React from "react";
import { Box, Grid } from "@mui/material";
import BasicCard from "./BasicCard";
import BasicSlider from "./BasicSlider";

function BasicGrid({
  ofertas,
  handleSeleccionarOferta,
  handleChangeMonto,
  readOnly,
}) {
  const cards = ofertas.filter(
    (item) => item.renderType.toLowerCase() === "card"
  );
  const sliders = ofertas.filter(
    (item) => item.renderType.toLowerCase() === "slider"
  );
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Grid container spacing={2}>
        {sliders.map((oferta, i) => (
          <Grid key={i} item xs={12} sm={12} md={12}>
            <BasicSlider
              handleSeleccionarOferta={handleSeleccionarOferta}
              handleChangeMonto={handleChangeMonto}
              oferta={oferta}
              readOnly={readOnly}
            />
          </Grid>
        ))}

        {cards.map((oferta, i) => (
          <React.Fragment key={i}>
            {cards.length % 3 === 0 ? null : cards.length === 1 ? (
              <Grid item xs={0} sm={0} md={4} />
            ) : cards.length % 2 === 0 && i + 1 === cards.length ? (
              <Grid item xs={0} sm={0} md={4} />
            ) : cards.length % 2 !== 0 && i + 1 === cards.length - 1 ? (
              <Grid item xs={0} sm={0} md={2} />
            ) : null}
            <Grid item xs={12} sm={12} md={4}>
              <BasicCard
                handleSeleccionarOferta={handleSeleccionarOferta}
                oferta={oferta}
              />
            </Grid>
            {cards.length % 3 === 0 ? null : cards.length === 1 ? (
              <Grid item xs={0} sm={0} md={4} />
            ) : cards.length % 2 === 0 && i + 1 === cards.length ? (
              <Grid item xs={0} sm={0} md={4} />
            ) : cards.length % 2 !== 0 && i + 1 === cards.length ? (
              <Grid item xs={0} sm={0} md={2} />
            ) : null}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
}

export default BasicGrid;
