import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepFourA } from "../../../../services/steps.services";
import { showEmotions } from "../../../../utils";

const usePermissions = () => {
  const dataset = useSelector((state) => state.config.dataset);
  const [permissions, setPermissions] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [devices, setDevices] = React.useState([]);
  const [selectedDevice, setSelectedDevice] = React.useState(null);

  const getVideoDevices = () => {
    if (
      // (dataset.operador && dataset.canal === "SOLICITUD_WEB") ||
      dataset.canal !== "SOLICITUD_WEB" &&
      dataset.canal !== "FISICO" &&
      dataset.canal !== "PASARELA"
    ) {
      setLoading(false);
      return setPermissions(true);
    }
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setDevices(videoDevices);
        setSelectedDevice(videoDevices[0]);
        setLoading(false);
        setPermissions(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setPermissions(false);
      });
  };

  const handlePermission = (solicitudTipo) => {
    setLoading(true);
    if (
      // (dataset.operador && dataset.canal === "SOLICITUD_WEB") ||
      dataset.canal !== "SOLICITUD_WEB" &&
      dataset.canal !== "FISICO" &&
      dataset.canal !== "PASARELA"
    ) {
      setLoading(false);
      return setPermissions(true);
    }
    if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
      navigator.userMedia = navigator.mozGetUserMedia || navigator.getUserMedia;
      if (!navigator.userMedia) {
        alert("Porfavor, cambie o actualize su navegador");
        return;
      }
      navigator.userMedia(
        {
          video: true,
        },
        (stream) => {
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
          getVideoDevices();
          setLoading(false);
        },
        (err) => {
          setPermissions(false);
          setLoading(false);
        }
      );
      return;
    } else {
      if (
        navigator.mediaDevices.getUserMedia ||
        navigator.mediaDevices.enumerateDevices
      ) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then(function (stream) {
            stream.getTracks().forEach(function (track) {
              track.stop();
            });
            getVideoDevices();
          })
          .catch(function (err) {
            setPermissions(false);
            setLoading(false);
          });
      } else {
        setPermissions(false);
        setLoading(false);
      }
      return;
    }
  };

  return {
    permissions,
    loading,
    devices,
    selectedDevice,
    handlePermission,
    setSelectedDevice,
    getVideoDevices,
  };
};

const useCamera = () => {
  const dataset = useSelector((state) => state.config.dataset);
  const dispatch = useDispatch();
  const [openError, setOpenError] = React.useState(false);
  const [intentos, setIntentos] = React.useState(3);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [takeAgain, setTakeAgain] = React.useState(true);
  const [snapshots, setSnapshots] = React.useState({
    frontal: null,
    reverso: null,
    selfie: [],
  });
  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const submit = async (
    e,
    handleNextStep,
    handleMainNextStep,
    activeStep,
    maxStep
  ) => {
    e.preventDefault();
    if (
      snapshots.frontal === null ||
      snapshots.reverso === null ||
      snapshots.selfie.length === 0
    ) {
      setOpenError(true);
    } else {
      const formData = new FormData();
      formData.append("solicitudId", String(dataset.id));
      formData.append("id", String(dataset.id));
      formData.append("frontal", snapshots.frontal);
      formData.append("reverso", snapshots.reverso);
      formData.append("imagenes_1", snapshots.selfie[0]);
      formData.append("imagenes_2", snapshots.selfie[1]);
      formData.append("imagenes_3", snapshots.selfie[2]);
      formData.append("imagenes", snapshots.selfie[0]);
      formData.append("imagenes", snapshots.selfie[1]);
      formData.append("imagenes", snapshots.selfie[2]);
      formData.append("celular", dataset.telefono);
      formData.append("documento", dataset.cedula);
      formData.append("sucursal", dataset.sucursal);
      formData.append("canal", dataset.canal);
      formData.append(
        "solicitudFromAsesor",
        window.location.hostname.includes("operador")
      );
      formData.append(
        "solicitudWithoutAsesor",
        !window.location.hostname.includes("operador")
      );

      setOpenModal(true);
      stepFourA(formData, dataset.token)
        .then((response) => {
          setOpenModal(false);
          if (response.data.frValidated === true) {
            if (showEmotions(dataset.sucursal)) {
              setTimeout(() => {
                dispatch({ type: "hide/emotions" });
                dispatch({ type: "step/fr/validated" });
              }, 4500);
              dispatch({ type: "show/veryHappyFace" });
            }
            dispatch({ type: "step/fr/validated" });
            if (activeStep === maxStep) {
              handleMainNextStep();
            } else {
              handleNextStep();
            }
          } else {
            if (response.data.mustBeCalled) {
              dispatch({
                type: "show/help",
                payload: {
                  helpTitle: "No se pudo validar tu identidad.",
                  helpBody: "Un agente se contactará a la brevedad.",
                  showButton: false,
                },
              });
            } else if (response.data.mustGoToIV) {
              dispatch({ type: "show/spinner" });
              dispatch({ type: "show/questions" });
              if (activeStep === maxStep) {
                handleMainNextStep();
              } else {
                handleNextStep();
              }
            } else {
              setOpenModal(false);
              setOpenError(true);
              setOpenAlert(true);
              setIntentos(
                response.data.pffFrReintentos - response.data.intentosFR
              );
            }
          }
        })
        .catch((error) => {
          try {
            const { returnCode } = error.response.data;
            const { data } = error.response.data;
            if (
              (returnCode && returnCode.includes("BL.found_record")) ||
              (data.returnCode && data.returnCode.includes("BL.found_record"))
            ) {
              dispatch({
                type: "show/error",
                payload: {
                  errorTitle: "Sin ofertas",
                  errorBody: dataset.sinOfertasText,
                },
              });
            }
          } catch {
            console.log(error);
          }
          console.log(error);
          setOpenModal(false);
          setOpenError(true);
          if (intentos >= 1) {
            setOpenModal(false);
            setOpenAlert(true);
            setIntentos((intento) => intento - 1);
          } else {
            dispatch({
              type: "show/help",
              payload: {
                helpTitle: "No hemos podido verificar tu identidad",
                helpBody: "Un agente se contactara a la brevedad",
                showButton: false,
              },
            });
          }
        });
    }
  };
  return {
    submit,
    setSnapshots,
    snapshots,
    setTakeAgain,
    takeAgain,
    openModal,
    openAlert,
    intentos,
    setOpenError,
    openError,
    closeAlert,
  };
};

export { useCamera, usePermissions };
