import axios from "axios";

export const get = (url, token, params) =>
  call("get", url, token, null, params);
export const post = (url, token, data) => call("post", url, token, data);
export const patch = (url, token, data) => call("patch", url, token, data);
export const del = (url, token) => call("delete", url, token);

function call(method, url, token, data, params) {
  let headers = {};

  // add headers
  if (token) headers = { ...headers, Authorization: `Bearer ${token}` };
  if (data) headers = { ...headers, "Content-Type": "application/json" };

  return axios({
    method,
    url: `${process.env.REACT_APP_API_URL}/${url}`,
    headers,
    data,
    params,
  });
}
