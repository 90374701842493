import { Card, CardContent, Divider, Typography } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import "./Core.css";
import { currencyFormat } from "../../../utils";

export default function CoreAmpliacion() {
  const { config } = useSelector((state) => state);
  const { valeHeredadoId, montoHeredado } = config.dataset;
  const { showSuccess, showPrevVale } = config.core.ampliacion.text;
  const hide = config.core.ampliacion.hide;

  if (hide) return <></>;
  return (
    <Card sx={{ height: { sm: "auto" }, boxShadow: "none" }}>
      <CardContent sx={{ padding: "2rem !important" }}>
        <Typography
          sx={{
            textTransform: "uppercase",
            letterSpacing: "-0.5px !important",
          }}
          fontWeight="700"
        >
          Solicitud de ampliación
        </Typography>

        {(showSuccess || showPrevVale) && <Divider sx={{ my: 2 }} />}

        {showSuccess && (
          <Typography variant="body1" sx={{ mb: 2 }}>
            Felicitaciones!. Es posible ampliar tu crédito vigente. Por favor
            confirme o actualice sus datos.
          </Typography>
        )}

        {showPrevVale && montoHeredado && (
          <>
            <Typography variant="body2">
              Número Vale Vigente: #{valeHeredadoId}
            </Typography>
            <Typography variant="body2">
              Monto cancelación de vale vigente que se sumará al nuevo capital:{" "}
              ${currencyFormat(montoHeredado)} al{" "}
              {moment().format("DD/MM/YYYY")}. Este monto puede cambiar si no se
              confirma la operación en el día de hoy.
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}
