import { isStaging } from "./rootConf";

function is() {
  return window.location.hostname.includes("finmutual");
}

function updateState(state) {
  state.dataset.flavor = "FINMUTUAL";
  state.dataset.icon = window.location.hostname.includes("staging")
    ? "FINMUTUALSTAGING"
    : "FINMUTUAL";
  state.dataset.texto_flavor = "Aprobá YA tu crédito, sin papeles";
  state.dataset.nombre_flavor = "Solicitudes | finMutual";
  state.dataset.apodo_flavor = "Fin Mutual";
  state.dataset.operador = window.location.hostname.includes("operador");
  state.dataset.recaptcha = false;
  state.dataset.urlTerminos = "./assets/tyc/finuy_tyc_general.pdf";
  state.dataset.sinOfertasText =
    "Lo siento. En este momento no tenemos una oferta para vos.";
  state.displayNavbar = true;
  state.displayNavbarOptions = false;
  state.displaySide = false;
  state.side = false;
  state.dataset.myAccount = isStaging()
    ? "https://staging-micuenta.finmutual.com.uy"
    : "https://micuenta.finmutual.com.uy";
  state.dataset.externalLink = window.location.hostname.includes("staging")
    ? "https://staging-operador.finmutual.com.uy"
    : window.location.hostname.includes("operador")
    ? "https://operador.finmutual.com.uy"
    : "https://finmutual.com.uy";
  state.dataset.backgroundUrl =
    "./assets/backgrounds/finmutual_background.webp";
}

export default {
  is,
  updateState,
};
