import DescriptionIcon from "@mui/icons-material/Description";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepSelectAdditionals } from "../../../services/steps.services";
import { errorSetter } from "../../../utils";
import Error from "../../Warnings/Error";

const StepSelectAdditionals = ({
  handleNextStep,
  handleBackStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dispatch = useDispatch();

  const [openAlert, setOpenAlert] = useState(false);
  const [errorText, setErrorText] = useState("Ha ocurrido un error");
  const dataset = useSelector((state) => state.config.dataset);
  const cantMinSeleccionable = dataset.adicionales.minToSelect;

  // Content
  const [adicionalesListSelected, setAdicionalesListSelected] = useState([]);

  const handleSubmit = async (e) => {
    setOpenAlert(false);
    dispatch({ type: "show/spinner" });
    e.preventDefault();
    stepSelectAdditionals(
      dataset.canal,
      dataset.id,
      dataset.cedula,
      dataset.telefono,
      dataset.sucursal,
      dataset.token,
      adicionalesListSelected
    )
      .then((response) => {
        if (response.data.productoFinanciero.sendForDigitalSignature) {
          dispatch({ type: "step/two-b/remote" });
        }
        dispatch({
          type: "step/select-additionals",
          payload: {
            adicionales: {
              selected: adicionalesListSelected,
              selectedVerified: response.data.solicitudAdicionalLista?.map(
                (o) => o.productoFinancieroAdicional
              ),
              montoCuotaConAdicionales: response.data.montoCuotaConAdicionales,
            },
          },
        });
        dispatch({ type: "show/spinner" });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
      })
      .catch((error) => {
        console.log(error);
        errorSetter(setOpenAlert, setErrorText, error);
        dispatch({ type: "hide/spinner" });
      });
  };

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={4}
      onSubmit={(e) => handleSubmit(e)}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        justifyItems="center"
        alignItems="center"
        sx={{
          backgroundColor: "background.alternate",
          borderRadius: "1rem",
        }}
      >
        <Typography variant="h6">
          Seleccione el servicio adicional que le interesa contratar
        </Typography>
        {cantMinSeleccionable > 0 && (
          <Typography variant="body1" color="text.secondary">
            Seleccione <b>mínimo {cantMinSeleccionable}</b> y{" "}
            <b>máximo {dataset.adicionales.maxToSelect}</b> adicionales
          </Typography>
        )}
        <Content
          dataset={dataset}
          selected={adicionalesListSelected}
          setSelected={setAdicionalesListSelected}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        gap={4}
      >
        <Button
          variant="contained"
          type="submit"
          color="button"
          disabled={adicionalesListSelected.length < cantMinSeleccionable}
        >
          Continuar
        </Button>
      </Box>
      <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
    </Box>
  );
};

export default StepSelectAdditionals;

function Content({ dataset, selected, setSelected }) {
  const adicionalesList = dataset.adicionales.list;
  const cantMaxSeleccionable = dataset.adicionales.maxToSelect;
  // const cantMinSeleccionable = dataset.adicionales.minToSelect;

  const handleToggle = (item) => {
    const currentIndex = selected.indexOf(item);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      if (newSelected.length < cantMaxSeleccionable) {
        newSelected.push(item);
      } else if (cantMaxSeleccionable === 1) {
        newSelected.splice(0, 1);
        newSelected.push(item);
      }
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleLinkClick = (e) => {
    // Detener la propagación del evento para evitar que el Checkbox se active/desactive
    e.stopPropagation();
  };

  return (
    <List>
      {adicionalesList.map((item, index) => (
        <ListItem
          key={index}
          dense
          onClick={() => handleToggle(item)}
          sx={{
            marginBottom: 2,
            backgroundColor:
              selected.indexOf(item) !== -1 ? "button.light2" : "button.light",
            borderRadius: "1rem",
          }}
        >
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                checked={selected.indexOf(item) !== -1}
                color="secondary"
              />
            }
            label=""
          />
          <ListItemText
            primary={<b>{item.nombre}</b>}
            secondary={`Precio: $${item.montoMonedaExpresion}`}
          />
          <IconButton
            sx={{ padding: 2, marginLeft: 4 }}
            component={Link}
            href={item.url}
            target="_blank"
            rel="noopener"
            aria-label="términos y condiciones"
            color="secondary"
            onClick={handleLinkClick} // Añadir el controlador de eventos aquí
          >
            <DescriptionIcon />
          </IconButton>
        </ListItem>
      ))}
      {/* {selected.length < cantMinSeleccionable && (
        <Typography paddingTop={2} className="hidden" color="error">
          Cantidad mínima a seleccionar: {cantMinSeleccionable}
        </Typography>
      )} */}
    </List>
  );
}
