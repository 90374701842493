import { FormControl, FormHelperText, Grid, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { capitalize } from "../../utils";
import AutoComplete from "./AutoComplete";

function LocalidadAndBarrioInput({
  validation,
  barrio,
  localidad,
  showBarrio,
  setShowBarrio,
  handleBarrio,
}) {
  React.useEffect(() => {
    if (barrio.length > 0) {
      setTimeout(() => {
        setShowBarrio(true);
      }, 1100);
    } else {
      setShowBarrio(false);
    }
    // eslint-disable-next-line
  }, [barrio]);
  const dataset = useSelector((state) => state.config.dataset);
  const theme = useTheme();
  return (
    <Grid container>
      <Grid
        item
        xs={barrio.length > 0 ? 6 : 12}
        sm={barrio.length > 0 ? 6 : 12}
        lg={barrio.length > 0 ? 6 : 12}
        sx={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: "1000ms",
          }),
          paddingRight: barrio.length > 0 ? "0.25rem" : "none",
        }}
      >
        <FormControl required sx={{ width: "100%" }}>
          <AutoComplete
            id="localidad"
            required
            name="localidad"
            select
            fullWidth
            label="Localidad"
            value={localidad.length ? validation.values.localidad : ""}
            onChange={(e) => {
              if (dataset.divisionTercerNivel) {
                handleBarrio(e);
              }
              validation.values.localidad = "";
              validation.handleChange(e);
            }}
            error={
              validation.touched.localidad &&
              Boolean(validation.errors.localidad)
            }
            disabled={Boolean(localidad.length === 0)}
            items={localidad.map((obj) => ({
              value: obj.id,
              label: capitalize(obj.label),
            }))}
          />

          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.localidad && validation.errors.localidad}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={showBarrio ? 6 : 0}
        sm={showBarrio ? 6 : 0}
        lg={showBarrio ? 6 : 0}
        sx={{
          display: showBarrio ? "block" : "none",
          paddingLeft: "0.25rem",
        }}
      >
        <FormControl required sx={{ width: "100%" }}>
          <AutoComplete
            id="barrio"
            name="barrio"
            select
            fullWidth
            label="Barrio"
            value={validation.values.barrio}
            onChange={(e) => {
              validation.values.barrio = "";
              validation.handleChange(e);
            }}
            error={
              validation.touched.barrio && Boolean(validation.errors.barrio)
            }
            disabled={Boolean(barrio.length === 0)}
            items={barrio.map((depto) => ({
              value: depto.id,
              label: capitalize(depto.label),
            }))}
          />

          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.localidad && validation.errors.localidad}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default LocalidadAndBarrioInput;
