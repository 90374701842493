import MenuIcon from "@mui/icons-material/Menu";
import { useScrollTrigger, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import * as React from "react";
import { useSelector } from "react-redux";
import "./Components.css";

const navItems = ["Inicio", "Quiénes somos", "Requisitos", "FAQ"];

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function DrawerAppBar(props) {
  const theme = useTheme();
  const dataset = useSelector((state) => state.config.dataset);
  const displayNavbarOptions = useSelector(
    (state) => state.config.displayNavbarOptions
  );
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logo = getLogo(dataset.navbarLogo, dataset.icon);

  return (
    <HideOnScroll {...props}>
      <AppBar
        sx={{
          borderRadius: "0px !important",
          paddingX: "2rem",
          paddingY: "1rem",
          backgroundColor: theme.palette.primary.main,
        }}
        component="nav"
      >
        <Toolbar>
          <Box
            sx={{ marginX: { xs: "auto", sm: "0rem", md: "0rem", lg: "0rem" } }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <img
                src={logo}
                srcSet={logo}
                alt="Logo"
                style={{
                  width: "150px",
                  alignSelf: "center",
                  cursor: dataset.externalLink ? "pointer" : "auto",
                }}
                loading="lazy"
                onClick={() => {
                  if (dataset.externalLink) {
                    window.open(dataset.externalLink, "_blank");
                  }
                }}
              />
            </Box>
          </Box>
          {displayNavbarOptions && (
            <>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
              >
                {navItems.map((item) => (
                  <Button
                    key={item}
                    sx={{
                      color: "#fff",
                    }}
                    className="mobileMenuText"
                  >
                    {item}
                  </Button>
                ))}
              </Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: { xs: "block", sm: "block", md: "none", lg: "none" },
                }}
              >
                <MenuIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
        {displayNavbarOptions && (
          <Collapse
            sx={{
              transition:
                "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
              backgroundColor: "black",
              marginX: "2rem",
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
            orientation="vertical"
            className="collapse"
            in={mobileOpen}
          >
            <List>
              {navItems.map((item) => (
                <ListItem key={item} disablePadding>
                  <ListItemButton
                    className="mobileMenuText"
                    sx={{ textAlign: "left", marginLeft: "1rem" }}
                  >
                    <ListItemText
                      sx={{
                        color: theme.palette.text.contrast,
                      }}
                      primary={item}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </AppBar>
    </HideOnScroll>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;

function getLogo(navbarLogo, icon) {
  if (navbarLogo && navbarLogo.trim() !== "") return navbarLogo;
  return `./assets/images/${icon.toLowerCase()}_main_icon.webp`;
}
