import { useSelector } from "react-redux";
import "./Reactions.css";
import React from "react";
import { showEmotions } from "../../utils";

function Confeti(props) {
  const dataset = useSelector((state) => state.config.dataset);
  if (!showEmotions(dataset.sucursal)) {
    return <div>{props.children}</div>;
  }
  return (
    <div>
      <div className="container">
        <div className="confetti" />
        <div className="confetti" />
        <div className="confetti" />
        <div className="confetti" />
        <div className="confetti" />
        <div className="confetti" />
        <div className="confetti" />
        <div className="confetti" />
        <div className="confetti" />
        <div className="confetti" />
      </div>
      {props.children}
    </div>
  );
}

export default Confeti;
