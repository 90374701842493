import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";

const Adicionales = ({ dataset, hidden }) => {
  if (hidden) return <></>;

  const adicionales = dataset.adicionales.selectedVerified;

  return (
    <Box display="flex" flexDirection="row" gap={4}>
      <Box display="flex" flexDirection="column" gap={1} sx={{ width: "100%" }}>
        <Typography>
          Adicionales contratados incluidos en el valor cuota
        </Typography>

        <List sx={{ padding: 0 }}>
          {adicionales.map((item, index) => (
            <ListItem key={index} dense sx={{ marginBottom: 2 }}>
              <ListItemText
                primary={
                  <Typography fontWeight="bold">{item.nombre}</Typography>
                }
                sx={{ flexShrink: 5 }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Adicionales;
