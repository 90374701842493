import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import axios from "axios";
import solicitudGeneration, {
  solicitudGenerationType,
} from "./solicitudGeneration";
import vinanConf from "./config/vinanConf";
import credivConf from "./config/credivConf";
import tiamartaConf from "./config/tiamartaConf";
import mayeuticConf from "./config/mayeuticConf";
import { isDemoFlavour, isLocalhost, isStaging } from "./config/rootConf";
import finmutualConf from "./config/finmutualConf";
import whitespaceConf from "./config/whitespaceConf";

const defaultCoreRefinanciacion = {
  hide: false,
  text: {
    showSuccess: false,
    showPrevVale: false,
  },
};

const FETCH_DEPTOS = createAsyncThunk("fetch/deptos", async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL +
      `/localidades/paisEstadoProvincia/${process.env.REACT_APP_COUNTRY_CODE}/all`,
    {
      headers: { "Access-Control-Allow-Origin": "*" },
    }
  );
  return response.data.map((depto) => ({ label: depto.nombre, id: depto.id }));
});

const APPLY_FLAVOR = createAction("flavor/apply");

const initialState = {
  title: [
    "Sobre vos",
    "Seleccioná la oferta que mas te sirva",
    "Confirmá tus pasos",
    "Validar Identidad",
    "",
  ],
  currentTitle: "",
  show: false,
  displayError: false,
  displayHelp: false,
  displayStepper: true,
  displayStepProgress: false,
  displayMain: true,
  displaySide: true,
  displayNavbar: false,
  displayNavbarOptions: false,
  showHappyFace: false,
  showFRFace: false,
  showVeryHappyFace: false,
  showExpectationFace: false,
  showThinkingFace: false,
  showSadFace: false,
  side: false,
  spinnerText: "",
  montoDeuda: 0,
  displaySpinner: false,
  deuda: false,
  conOfertas: "",
  generationType: solicitudGenerationType.DEFAULT,
  step: {
    showMobileValidation: true,
    showFaceRecognition: true,
    showIdValidator: true,
    showEmailValidation: true,
    showViaFinanciera: true,
    step1Text: "Tus datos",
    step2Text: "Confirmar",
    step3Text: "Validación",
    step4Text: "Confirmá tus pasos",
    title: "", // Si existe, sobreescribe el title del stepper(step1Text, step2Text, etc...)
  },
  core: {
    ampliacion: {
      hide: false,
      text: {
        showSuccess: false,
        showPrevVale: false,
      },
    },
    refinanciacion: defaultCoreRefinanciacion,
  },
  dataset: {
    callback: null,
    id: 0,
    sucursal: 3,
    token: null,
    cedula: "",
    telefono: "",
    ubicacion: {},
    nombre: "",
    apellido: "",
    segundoNombre: "",
    segundoApellido: "",
    primerVencimiento: "",
    monto: 0,
    cuotas: "",
    donde: "",
    opcion: "",
    direccion: "",
    banco: "",
    numCuenta: 0,
    fechaMaxima: "",
    fechaMinima: "",
    minMinimoMonto: 0,
    maxMaximoMonto: 0,
    error: false,
    errorTitle: "Por ahora no tenemos ofertas para vos",
    errorBody:
      "Esta decisión puede cambiar según tus antecedentes crediticios y nuestra politica comercial. Te recomendamos volver a consultar dentro de unos meses",
    errorFace: null,
    helpTitle: "Ha ocurrido un error",
    helpBody: "Un agente se contactara a la brevedad",
    showButton: true,
    externalLink: null,
    myAccount: null,
    createPassword: null,
    backgroundUrl: null,
    montoCuota: "",
    urlTerminos: "",
    adicionales: {
      list: [],
      minToSelect: null,
      maxToSelect: null,
      autoselect: null,
      selected: null,
      // From server
      montoCuotaConAdicionales: null,
      selectedVerified: null,
    },
    urlTerminosFinal: "",
    urlTerminosFinalCliente: "",
    urlInstructivo: "",
    idExterno: "",
    phoneContactForHelp: "",
    mailContactForHelp: "",
    montoDesembolsar: 0,
    destinos: [],
    deudaPrevia: 0,
    flavor: "",
    icon: "",
    operador: true,
    canal: "SOLICITUD_WEB",
    operadorData: {
      mail: "",
      sucursal: { nombre: "", id: "", img: null, warning: null },
    },
    apodo_flavor: "",
    nombre_flavor: "",
    texto_flavor: "Aprobá YA tu crédito, sin papeles",
    emailOptional: true,
    divisionTercerNivel: false,
    porConfirmar: false,
    ofertas: [],
    numCuotas: "",
    destinoElegido: "",
    otorgarSiempre: false,
    maxMontoSegunTratamiento: "",
    recaptcha: false,
    solicitudTipo: "YA_FIRMADA",
    familia: "",
    moreThanOne: false,
    remoteSignature: false,
    doneJumping: false,
    tokenRemote: null,
    frValidated: false,
    slot: null,
    tipoDeCalculo: null,
    cantidadCuotas: "",
    monedaExpresion: "Pesos - UYU",
    monedaNacional: "Pesos - UYU",
    monedaExpresionCuota: "Pesos - UYU",
    nivelEstudio: "",
    estadoCivil: "",
    sinOfertasText:
      "Lo siento. En este momento no tenemos una oferta para vos.",
    sinIntentos: false,
    valeHeredadoId: null,
    montoHeredado: null,
    discountPercentage: null,

    genero: "",
    fechaNacimiento: "",
    email: "",
    ingresos: 0,
    departamento: "",
    localidad: "",
    relacionLaboral: "",

    // Utils
    monedaNacionalName: undefined, // Caso de uso: Cuando pone los ingresos, guie a la persona con "Pesos Uruguayos"

    // Pasarela
    pasarelaWebhookUrl: undefined,
    pasarelaName: undefined,
    pasarelaSecondsToBackOnError: undefined,
    pasarelaSecondsToBackOnSuccess: undefined,
  },
  jumpData: {
    id: "",
    token: "",
    step: "",
  },
  deptos: [],
};
const configReducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_DEPTOS.fulfilled, (state, action) => {
    state.deptos.push(...action.payload);
  });
  builder.addCase(APPLY_FLAVOR, (state, action) => {
    if (credivConf.is()) credivConf.updateState(state);
    else if (vinanConf.is()) vinanConf.updateState(state);
    else if (isLocalhost() || (isStaging() && isDemoFlavour())) {
      // vinanConf.updateState(state);
      mayeuticConf.updateState(state);
      // caixaConf.updateState(state);
      // finmutualConf.updateState(state);
    } else if (finmutualConf.is()) finmutualConf.updateState(state);
    else if (tiamartaConf.is()) tiamartaConf.updateState(state);
    else whitespaceConf.updateState(state);
  });
  builder.addCase("change/step1", (state, action) => {
    state.step.step1Text = action.payload;
  });
  builder.addCase("change/step2", (state, action) => {
    state.step.step2Text = action.payload;
  });
  builder.addCase("change/step3", (state, action) => {
    state.step.step3Text = action.payload;
  });
  builder.addCase("change/step4", (state, action) => {
    state.step.step4Text = action.payload;
  });
  builder.addCase("show/side", (state, action) => {
    state.displaySide = !state.displaySide;
  });
  builder.addCase("hide/side", (state, action) => {
    state.displaySide = false;
  });
  builder.addCase("hide/emotions", (state, action) => {
    state.showHappyFace = false;
    state.showSadFace = false;
    state.showThinkingFace = false;
    state.showVeryHappyFace = false;
    state.showExpectationFace = false;
    state.showFRFace = false;
  });
  builder.addCase("show/showFRFace", (state, action) => {
    state.showFRFace = true;
  });
  builder.addCase("show/happyFace", (state, action) => {
    state.showHappyFace = true;
  });
  builder.addCase("show/expectationFace", (state, action) => {
    state.showExpectationFace = true;
  });
  builder.addCase("show/veryHappyFace", (state, action) => {
    state.showVeryHappyFace = true;
  });
  builder.addCase("show/sadFace", (state, action) => {
    state.showSadFace = true;
  });
  builder.addCase("show/thinkingFace", (state, action) => {
    state.showThinkingFace = true;
  });
  builder.addCase("show/spinner", (state, action) => {
    state.displaySpinner = !state.displaySpinner;
    state.spinnerText = "";
  });
  builder.addCase("show/spinner/text", (state, action) => {
    state.displaySpinner = !state.displaySpinner;
    state.spinnerText = action.payload;
  });
  builder.addCase("hide/spinner", (state, action) => {
    state.displaySpinner = false;
    state.spinnerText = "";
  });
  builder.addCase("on/spinner", (state, action) => {
    state.displaySpinner = true;
    state.spinnerText = "";
  });
  builder.addCase("show/main", (state, action) => {
    state.displayMain = true;
  });
  builder.addCase("hide/main", (state, action) => {
    state.displayMain = false;
  });
  builder.addCase("show/error", (state, action) => {
    state.displaySpinner = false;
    state.displayError = true;
    state.displayMain = false;
    state.displayStepper = false;
    state.dataset.error = true;
    state.displayHelp = false;
    state.dataset.errorTitle = action.payload.errorTitle;
    state.dataset.errorBody = action.payload.errorBody;
    state.dataset.errorFace = action.payload.errorFace;
  });
  builder.addCase("show/help", (state, action) => {
    state.displaySpinner = false;
    state.displayHelp = true;
    state.displayError = false;
    state.displayMain = false;
    state.displayStepper = false;
    state.dataset.helpBody = action.payload.helpBody;
    state.dataset.helpTitle = action.payload.helpTitle;
    state.dataset.showButton = action.payload.showButton;
  });
  builder.addCase("sucursal", (state, action) => {
    state.dataset.sucursal = action.payload;
  });
  builder.addCase("change/canal", (state, action) => {
    state.dataset.canal = action.payload.canal;
    state.dataset.operador = action.payload.operador;
  });
  builder.addCase("operador/datos", (state, action) => {
    state.dataset.operadorData.sucursal.nombre = action.payload.sucursal.nombre;
    state.dataset.operadorData.sucursal.id = action.payload.sucursal.id;
    state.dataset.operadorData.sucursal.img = action.payload.sucursal.imagenUrl;
    state.dataset.operadorData.sucursal.warning =
      action.payload.sucursal.warning;
    state.dataset.operadorData.mail = action.payload.mail;
    state.dataset.token = action.payload.token;
    state.dataset.canal = action.payload.canal;
  });
  builder.addCase("show/questions", (state, action) => {
    state.step.showIdValidator = true;
  });
  builder.addCase("hide/questions", (state, action) => {
    state.step.showIdValidator = false;
  });
  builder.addCase("done/jumping", (state, action) => {
    state.dataset.doneJumping = true;
  });
  builder.addCase("step/one-a", (state, action) => {
    state.dataset.id = action.payload.id;
    state.dataset.deudaPrevia = action.payload.deudaPrevia;
    state.dataset.cedula = action.payload.cedula;
    state.dataset.telefono = action.payload.telefono;
    state.dataset.emailOptional = action.payload.emailOptional;
    state.dataset.divisionTercerNivel = action.payload.divisionTercerNivel;
    state.dataset.solicitudTipo = action.payload.solicitudTipo;
    state.dataset.segundoNombre = action.payload.segundoNombre;
    state.dataset.segundoApellido = action.payload.segundoApellido;
    state.dataset.nombre = action.payload.nombre;
    state.dataset.apellido = action.payload.apellido;
    state.dataset.genero = action.payload.genero;
    state.dataset.fechaNacimiento = action.payload.fechaNacimiento;
    state.dataset.email = action.payload.email;
    state.dataset.ingresos = action.payload.ingresoDeclarado;
    state.dataset.departamento = action.payload.departamento;
    state.dataset.localidad = action.payload.localidad;
    state.dataset.relacionLaboral = action.payload.relacionLaboral;
    state.dataset.direccion = action.payload.direccion;
    state.displayStepProgress = true;
    state.dataset.nivelEstudio = action.payload.nivelEstudio;
    state.dataset.estadoCivil = action.payload.estadoCivil;

    // Utils
    state.dataset.monedaNacionalName = action.payload.monedaNacionalName;

    // Ampliacion / Refinanciacion
    state.dataset.valeHeredadoId = action.payload.valeHeredadoId;
    state.dataset.montoHeredado = action.payload.montoHeredado;
    state.dataset.discountPercentage = action.payload.discountPercentage;
    solicitudGeneration(state, action);

    state.dataset.montoPasarela = action.payload.montoPasarela;
  });
  builder.addCase("step/one-b", (state, action) => {
    state.dataset.nombre = action.payload.nombre;
    state.dataset.apellido = action.payload.apellido;
    state.dataset.segundoNombre = action.payload.segundoNombre;
    state.dataset.segundoApellido = action.payload.segundoApellido;
    state.dataset.ingresos = action.payload.ingresos;
    state.dataset.genero = action.payload.genero;
    state.dataset.relacionLaboral = action.payload.relacionLaboral;
    state.dataset.fechaNacimiento = action.payload.fechaNacimiento;
    state.dataset.email = action.payload.email;
    state.dataset.nivelEstudio = action.payload.nivelEstudio;
    state.dataset.estadoCivil = action.payload.estadoCivil;
    state.dataset.departamento = action.payload.departamento;
    state.dataset.localidad = action.payload.localidad;
    state.conOfertas = action.payload.conOfertas;
  });
  builder.addCase("step/family", (state, action) => {
    state.dataset.familia = action.payload.familia;
    state.dataset.ofertas = action.payload.ofertas;
    state.dataset.monedaNacional = action.payload.monedaNacional;
    state.dataset.slot = action.payload.slot;
    state.dataset.tipoDeCalculo = action.payload.tipoDeCalculo;
    // aca van a venir las ofertas
  });
  builder.addCase("step/family/more-than-one", (state, action) => {
    state.dataset.moreThanOne = true;
    // aca van a venir las ofertas
  });
  builder.addCase("step/two-a", (state, action) => {
    state.dataset.donde = action.payload.donde;
    state.dataset.opcion = action.payload.opcion;
    state.dataset.destinos = action.payload.destinos;
    state.conOfertas = action.payload.conOfertas;
    state.dataset.cuotas = action.payload.cuotas;
    state.dataset.monto = action.payload.monto;
    state.dataset.montoCuota = action.payload.montoCuota;
    state.dataset.primerVencimiento = action.payload.primerVencimiento;
    state.dataset.porConfirmar = true;
    state.dataset.destinoElegido = action.payload.destinoElegido;
    state.dataset.numCuotas = action.payload.numCuotas;
    state.dataset.monedaExpresion = action.payload.monedaExpresion;
    state.dataset.monedaExpresionCuota = action.payload.monedaExpresionCuota;
    state.dataset.adicionales = action.payload.adicionales;
  });
  builder.addCase("step/select-additionals", (state, action) => {
    state.dataset.adicionales = {
      ...state.dataset.adicionales,
      selected: action.payload.adicionales.selected,
      selectedVerified: action.payload.adicionales.selectedVerified,
      montoCuotaConAdicionales:
        action.payload.adicionales.montoCuotaConAdicionales,
    };
  });
  builder.addCase("step/two-b", (state, action) => {
    state.dataset.urlTerminos = action.payload.urlTerminos;
  });
  builder.addCase("step/two-b/remote", (state, action) => {
    state.dataset.remoteSignature = true;
  });
  builder.addCase("step/fr/validated", (state, action) => {
    state.dataset.frValidated = true;
  });
  builder.addCase("sin/intentos", (state, action) => {
    state.dataset.sinIntentos = true;
  });
  builder.addCase("step/four", (state, action) => {
    state.dataset.urlTerminosFinal = action.payload.tyc;
    state.dataset.urlTerminosFinalCliente = action.payload.tycc;
    state.dataset.idExterno = action.payload.idExterno;
    state.dataset.urlInstructivo = action.payload.instructivo;
    state.dataset.createPassword = action.payload.createPassword;
    state.dataset.montoDesembolsar = action.payload.montoDesembolsar;
    state.dataset.phoneContactForHelp = action.payload.phoneContactForHelp;
    state.dataset.mailContactForHelp = action.payload.mailContactForHelp;
  });
  builder.addCase("jump/steps", (state, action) => {
    state.jumpData.id = action.payload.id;
    state.jumpData.token = action.payload.token;
    state.jumpData.step = action.payload.step;
    state.displayStepProgress = true;
  });
  builder.addCase("remote/signature", (state, action) => {
    state.step.showViaFinanciera = false;
    state.dataset.tokenRemote = action.payload.token;
    state.displayStepProgress = true;
  });
  builder.addCase("show/display-step-progress", (state, action) => {
    state.displayStepProgress = true;
  });
  builder.addCase("hide/display-step-progress", (state, action) => {
    state.displayStepProgress = false;
  });
  builder.addCase("solicitud/dataset", (state, action) => {
    state.dataset.id = action.payload.id;
    state.dataset.tipoDocumento = action.payload.tipoDocumento;
    state.dataset.cedula = action.payload.documento;
    state.dataset.telefono = action.payload.celular;
    state.dataset.nombre = action.payload.nombre;
    state.dataset.apellido = action.payload.apellido;
    state.dataset.email = action.payload.email;
    state.dataset.fechaMaxima = action.payload.fechaMaxima;
    state.dataset.fechaMinima = action.payload.fechaMinima;
    state.dataset.maxMaximoMonto = action.payload.maxMaximoMonto;
    state.dataset.minMinimoMonto = action.payload.minMinimoMonto;
    state.dataset.otorgarSiempre = action.payload.otorgarSiempre;
    state.dataset.maxMontoSegunTratamiento =
      action.payload.maxMontoSegunTratamiento;
    state.dataset.destinoElegido = action.payload.destinoElegido;
    state.dataset.donde = action.payload.destinoElegido;
    state.dataset.tyc = action.payload.tyc;
    state.dataset.tycc = action.payload.tycc;
    state.dataset.idExterno = action.payload.idExterno;
    state.dataset.destinos = action.payload.destinos;
    state.dataset.primerVencimiento = action.payload.primerVencimiento;
    state.dataset.cuotas = action.payload.cuotas;
    state.dataset.montoCuota = action.payload.montoCuota;
    state.dataset.monto = action.payload.montoSolicitado;
    state.conOfertas = action.payload.conOfertas;
    state.dataset.emailOptional = action.payload.emailOptional;
    state.dataset.solicitudTipo = action.payload.solicitudTipo;
    state.dataset.localidad = action.payload.localidad;
    state.dataset.departamento = action.payload.departamento;
    state.dataset.token = action.payload.token;
    state.dataset.canal = action.payload.canal;
    state.step.showFaceRecognition = action.payload.showFaceRecognition;
    state.step.showMobileValidation = action.payload.showMobileValidation;
    state.step.showEmailValidation = action.payload.showEmailValidation;
    state.step.showIdValidator = action.payload.showIdValidator;
    state.dataset.segundoApellido = action.payload.segundoApellido;
    state.dataset.segundoNombre = action.payload.segundoNombre;
    state.dataset.sucursal = action.payload.sucursalId;
    state.dataset.slot = action.payload.slot;
    state.dataset.operadorData.sucursal = {
      nombre: action.payload.sucursal?.nombre,
      id: action.payload.sucursal?.id,
      img: action.payload.sucursal?.imagenUrl,
      warning: action.payload.sucursal?.warning,
    };
    state.dataset.operadorData.mail = action.payload.mailOperador;
    state.dataset.nivelEstudio = action.payload.nivelEstudio;
    state.dataset.estadoCivil = action.payload.estadoCivil;
    state.dataset.relacionLaboral = action.payload.relacionLaboral;
    state.dataset.fechaNacimiento = action.payload.fechaNacimiento;
    state.dataset.ingresos = action.payload.ingresoDeclarado;
    state.dataset.genero = action.payload.genero;
    state.dataset.opcion = action.payload.destinoDineroLabel;

    // Utils
    state.dataset.monedaNacionalName = action.payload.monedaNacionalName;

    // Ampliacion / Refinanciacion
    state.dataset.valeHeredadoId = action.payload.valeHeredadoId;
    state.dataset.montoHeredado = action.payload.montoHeredado;
    state.dataset.discountPercentage = action.payload.discountPercentage;
    solicitudGeneration(state, action);

    // Adicionales
    state.dataset.adicionales = {
      ...state.dataset.adicionales,
      selected: action.payload.adicionales?.selected,
      selectedVerified: action.payload.adicionales?.selectedVerified,
      montoCuotaConAdicionales:
        action.payload.adicionales?.montoCuotaConAdicionales,

      list: action.payload.adicionales?.list,
      maxToSelect: action.payload.adicionales?.cantMaxSeleccionable,
      minToSelect: action.payload.adicionales?.cantMinSeleccionable,
      autoselect: action.payload.adicionales?.autoselect,
    };

    // Pasarela
    state.dataset.pasarelaWebhookUrl = action.payload.pasarelaWebhookUrl;
    state.dataset.pasarelaName = action.payload.pasarelaName;
    state.dataset.pasarelaSecondsToBackOnError =
      action.payload.pasarelaSecondsToBackOnError;
    state.dataset.pasarelaSecondsToBackOnSuccess =
      action.payload.pasarelaSecondsToBackOnSuccess;
  });
  // Modify step titles
  builder.addCase("step/title/add", (state, action) => {
    state.step.title = action.payload;
  });
  builder.addCase("step/title/delete", (state, action) => {
    state.step.title = "";
  });
  // Ampliacion
  builder.addCase("core/ampliacion/text/success/show", (state, action) => {
    state.core.ampliacion.text.showSuccess = true;
  });
  builder.addCase("core/ampliacion/text/success/hide", (state, action) => {
    state.core.ampliacion.text.showSuccess = false;
  });
  builder.addCase("core/ampliacion/text/prev-vale/show", (state, action) => {
    state.core.ampliacion.text.showPrevVale = true;
  });
  builder.addCase("core/ampliacion/text/prev-vale/hide", (state, action) => {
    state.core.ampliacion.text.showPrevVale = false;
  });
  builder.addCase("core/ampliacion/hide", (state, action) => {
    state.core.ampliacion.hide = true;
  });

  // Refinanciacion
  builder.addCase("core/refinanciacion/text/success/show", (state, action) => {
    adjustRefinanciacionState(state);
    state.core.refinanciacion.text.showSuccess = true;
  });
  builder.addCase("core/refinanciacion/text/success/hide", (state, action) => {
    adjustRefinanciacionState(state);
    state.core.refinanciacion.text.showSuccess = false;
  });
  builder.addCase(
    "core/refinanciacion/text/prev-vale/show",
    (state, action) => {
      adjustRefinanciacionState(state);
      state.core.refinanciacion.text.showPrevVale = true;
    }
  );
  builder.addCase(
    "core/refinanciacion/text/prev-vale/hide",
    (state, action) => {
      adjustRefinanciacionState(state);
      state.core.refinanciacion.text.showPrevVale = false;
    }
  );
  builder.addCase("core/refinanciacion/hide", (state, action) => {
    adjustRefinanciacionState(state);
    state.core.refinanciacion.hide = true;
  });
});

export { APPLY_FLAVOR, FETCH_DEPTOS };
export default configReducer;

function adjustRefinanciacionState(state) {
  if (!state.core.refinanciacion) {
    state.core.refinanciacion = defaultCoreRefinanciacion;
  }
}
