import { TextField } from "@mui/material";
import React from "react";

function TextInput({
  type,
  name,
  id,
  label,
  value,
  onChange,
  error,
  disabled,
  autoComplete,
  required,
  onBlur,
  onKeyDown,
  fullWidth,
  size,
  inputProps,
}) {
  return (
    <TextField
      disabled={disabled}
      type={type ? type : "text"}
      name={name}
      id={id}
      autoComplete={autoComplete}
      required={required}
      label={label}
      // opcion 1 para agrandar font
      // InputLabelProps={{ style: { fontSize: 20 } }}
      // opcion 2 para agrandar font
      // label={
      //   <Typography variant="h6">
      //     {label}
      //   </Typography>
      // }
      value={value}
      fullWidth={fullWidth}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      size={size}
      inputProps={inputProps}
    />
  );
}

export default TextInput;
