import { Box, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import "../../index.css";

const Emoji = styled("div")({
  "@keyframes pulsate": {
    from: {
      transform: "scale(1)",
    },
    to: {
      transform: "scale(1.3)",
    },
  },
  animation: "pulsate 1s infinite ease",
  width: "4rem",
  height: "4rem",
  backgroundImage: "url(./assets/reactions/ExpectationFace.png)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
});

function FRFace() {
  const showFRFace = useSelector((state) => state.config.showFRFace);
  const [show, setShow] = React.useState(showFRFace);
  React.useEffect(() => {
    setShow(showFRFace);
  }, [showFRFace]);
  if (!show) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 100,
      }}
    >
      <Emoji />
    </Box>
  );
}

export default FRFace;
