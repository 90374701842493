export const solicitudGenerationType = {
  AMPLIACION: "AMPLIACION",
  REFINANCIACION: "REFINANCIACION",
  DEFAULT: "DEFAULT",
};

export default function solicitudGeneration(state, action) {
  if (action.payload.generationType === solicitudGenerationType.AMPLIACION) {
    // state.step.showMobileValidation = false;
    state.generationType = solicitudGenerationType.AMPLIACION;
  } else if (
    action.payload.generationType === solicitudGenerationType.REFINANCIACION
  ) {
    // state.step.showMobileValidation = false;
    state.generationType = solicitudGenerationType.REFINANCIACION;
  }
}
