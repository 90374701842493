import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documentos: [
    // {
    //   cedula: "",
    //   direccion: "",
    //   genero: "",
    //   fechaNacimiento: "",
    //   email: "",
    //   ingresos: 0,
    //   departamento: "",
    //   localidad: "",
    //   relacionLaboral: "",
    // },
  ],
};

const prevRequestSlice = createSlice({
  name: "prevRequest",
  initialState,
  reducers: {
    add(state, action) {
      // if exist cedula in array, update it
      const ci = action.payload.cedula;
      const iDocument = state.documentos.findIndex((doc) => doc.cedula === ci);
      if (iDocument !== -1) {
        const prev = state.documentos[iDocument];
        state.documentos[iDocument] = {
          ...prev, // Old
          ...action.payload, // New
        };
      }
      // else add new document
      else state.documentos.push(action.payload);

      // if there are more than 5 documents, remove the first one
      if (state.documentos.length > 5) state.documentos.shift();
    },
    update(state, action) {
      const ci = action.payload.cedula;
      const iDocument = state.documentos.findIndex((doc) => doc.cedula === ci);
      if (iDocument !== -1) {
        const prev = state.documentos[iDocument];
        state.documentos[iDocument] = {
          ...prev, // Old
          ...action.payload, // New
        };
      }
    },
  },
});

export const prevRequestActions = prevRequestSlice.actions;
export default prevRequestSlice.reducer;
