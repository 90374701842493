import {
  Alert,
  Box,
  Button,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
// import OTP from "../OTP/OTP";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  stepFourB,
  stepFourBMail,
  stepFourC,
  stepFourD,
} from "../../services/steps.services";
import { errorSetter } from "../../utils";
import { TextInput } from "../Inputs";

function Validator({ onChange = (e) => e, type }) {
  const theme = useTheme();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const dispatch = useDispatch();
  const dataset = useSelector((state) => state.config.dataset);
  const [valid, setValid] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const cd = dataset.operador ? 60000 : 120000;
  const [cdData, setCdData] = React.useState({ cd: cd, isCd: true });
  const [cantValidate, setCantValidate] = React.useState(false);
  const sendCode = type === "SMS" ? stepFourB : stepFourBMail;
  const validateCode = type === "SMS" ? stepFourC : stepFourD;
  React.useEffect(() => {
    if (cdData.isCd) {
      const interval = setInterval(() => {
        setCdData({
          cd: cdData.cd - 1000,
          isCd: cdData.cd > 1000,
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [cdData.isCd, cdData.cd]);
  const handleReenviar = () => {
    setCantValidate(true);
    setCdData((prev) => ({
      ...prev,
      isCd: true,
      cd: 120000,
    }));
    sendCode(
      dataset.id,
      dataset.cedula,
      dataset.email,
      dataset.telefono,
      dataset.canal,
      dataset.sucursal,
      dataset.token
    )
      .then((response) => setCantValidate(false))
      .catch((error) => {
        errorSetter(setOpenAlert, setErrorText, error);
      });
    // if (dataset.operador) {
    //   setOperadorJump(true);
    // }
  };
  const [errorText, setErrorText] = React.useState("Codigo invalido");
  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  // const handleJump = () => {
  //   console.log("se da el salto");
  //   setValid(true);
  //   setOpenSuccess(true);
  // };
  const handleValidar = () => {
    if (otp.length < 6) {
      setOpenAlert(true);
    } else {
      dispatch({ type: "show/spinner" });
      validateCode(
        dataset.id,
        dataset.cedula,
        dataset.email,
        dataset.telefono,
        otp,
        dataset.canal,
        dataset.sucursal,
        dataset.token
      )
        .then((response) => {
          dispatch({ type: "hide/spinner" });
          const valid =
            type === "SMS"
              ? response.data.smsValidated
              : response.data.emailValidated;
          if (valid) {
            setValid(true);
            setOpenSuccess(true);
          } else {
            setValid(null);
          }
        })
        .catch((error) => {
          dispatch({ type: "hide/spinner" });
          errorSetter(setOpenAlert, setErrorText, error);
          setValid(null);
        });
    }
  };
  React.useEffect(() => {
    if (valid) {
      onChange(valid);
    } else {
      onChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography>
        {type === "SMS"
          ? `Te enviamos un código de activación a tu celular ${dataset.telefono}`
          : "Te enviamos un correo con un código de activación a tu email"}
      </Typography>
      {!openSuccess && (
        <Box style={{ width: "100%" }}>
          <TextInput
            fullWidth
            inputProps={{ style: { textAlign: "center" } }}
            onChange={(e) => setOtp(e.target.value)}
          />
          <Box
            sx={{ marginTop: "1rem" }}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Button
              color="button"
              disabled={cdData.isCd || cantValidate}
              onClick={() => handleReenviar()}
              variant="outlined"
              sx={{ color: theme.palette.text.main }}
            >
              Reenviar
            </Button>
            <Button
              disabled={cantValidate}
              variant="contained"
              color="button"
              onClick={() => handleValidar()}
            >
              Validar
            </Button>
          </Box>
        </Box>
      )}
      <Box>
        {openSuccess && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
          >
            <Alert severity="info" sx={{ width: "100%" }}>
              {type === "SMS" ? "Celular Validado" : "Email validado"}
            </Alert>
          </motion.div>
        )}
      </Box>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          {errorText}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Validator;
