import { Box, Button, Typography, useTheme } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsSolicitudAmpliacion from "../../../hooks/useIsSolicitudAmpliacion";
import useIsSoliciudRefinanciacion from "../../../hooks/useIsSolicitudRefinanciacion";
import {
  stepConfirmacionIntermedia,
  stepSelectFamily,
} from "../../../services/steps.services";
import { currencyFormat, errorSetter } from "../../../utils";
import Error from "../../Warnings/Error";
import { stepSelectFamilyErrDispatch } from "./StepFamily";

const StepOneE = ({
  handleNextStep,
  handleBackStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const theme = useTheme();
  const isRefinanciacion = useIsSoliciudRefinanciacion();
  const isAmpliacion = useIsSolicitudAmpliacion();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorText, setErrorText] = React.useState("Ha ocurrido un error");

  const handleCambiarMonto = () => {
    dispatch({ type: "show/spinner" });
    stepSelectFamily(
      dataset.id,
      dataset.cedula,
      dataset.telefono,
      dataset.canal,
      dataset.sucursal,
      dataset.token,
      dataset.familia
    )
      .then((response) => {
        dispatch({ type: "show/spinner" });
        if (
          response.data.ofertasFormateadas &&
          response.data.ofertasFormateadas.length > 0
        ) {
          dispatch({
            type: "step/family",
            payload: {
              familia: dataset.familia,
              ofertas: response.data.ofertasFormateadas,
              monedaNacional: response.data.monedaNacionalNombre,
              slot: dataset.slot,
              tipoDeCalculo: dataset.tipoDeCalculo,
            },
          });
          handleBackStep();
        } else {
          dispatch({
            type: "show/error",
            payload: {
              errorTitle: "Ha ocurrido un error",
              errorBody: dataset.sinOfertasText,
            },
          });
        }
      })
      .catch((err) => {
        if (stepSelectFamilyErrDispatch(err, dispatch)) {
        } else {
          console.log(err);
          errorSetter(setOpenAlert, setErrorText, err);
        }

        dispatch({ type: "hide/spinner" });
        dispatch({ type: "hide/emotions" });
      });
  };
  const dispatch = useDispatch();
  const dataset = useSelector((state) => state.config.dataset);
  const isPasarela = dataset.montoPasarela ? true : false;
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (activeStep === maxStep) {
    //   handleMainNextStep();
    // } else {
    //   handleNextStep();
    // }
    setOpenAlert(false);
    dispatch({ type: "show/spinner" });
    e.preventDefault();
    stepConfirmacionIntermedia(
      dataset.canal,
      dataset.id,
      dataset.cedula,
      dataset.telefono,
      dataset.sucursal,
      dataset.token
    )
      .then((response) => {
        dispatch({ type: "show/spinner" });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
      })
      .catch((error) => {
        console.log(error);
        errorSetter(setOpenAlert, setErrorText, error);
        dispatch({ type: "hide/spinner" });
      });
  };

  useEffect(() => {
    if (isAmpliacion) {
      dispatch({ type: "step/title/add", payload: "Nuevo vale" });
    }

    // eslint-disable-next-line
    return () => {
      if (isAmpliacion) {
        dispatch({ type: "step/title/delete" });
        dispatch({ type: "core/ampliacion/hide" });
      }

      if (isRefinanciacion) {
        dispatch({ type: "step/title/delete" });
        dispatch({ type: "core/refinanciacion/hide" });
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={4}
      onSubmit={(e) => handleSubmit(e)}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        justifyItems="center"
        alignItems="center"
        sx={{
          backgroundColor: "background.alternate",
          borderRadius: "1rem",
          padding: { xs: "1rem", sm: "2rem", md: "2rem", lg: "3rem" },
        }}
      >
        <Logo dataset={dataset} />
        <Dialog
          isRefinanciacion={isRefinanciacion}
          isAmpliacion={isAmpliacion}
          dataset={dataset}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
      >
        <Button
          variant="outlined"
          color="button"
          sx={{ color: theme.palette.text.main }}
          onClick={() => handleCambiarMonto()}
        >
          {isPasarela ? "Cambiar cantidad de cuotas" : "Cambiar monto"}
        </Button>
        <Button color="button" variant="contained" type="submit">
          {isAmpliacion
            ? "Confirmar ampliación"
            : isRefinanciacion
            ? "Confirmar refinanciación"
            : "Confirmar préstamo"}
        </Button>
      </Box>
      <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
    </Box>
  );
};

export default StepOneE;

function Logo({ dataset }) {
  return (
    <img
      src={`./assets/images/${dataset.icon.toLowerCase()}_main_icon.webp`}
      srcSet={`./assets/images/${dataset.icon.toLowerCase()}_main_icon.webp`}
      alt={dataset.icon}
      style={{ width: "200px" }}
      loading="lazy"
    />
  );
}

function Dialog({ isRefinanciacion, isAmpliacion, dataset }) {
  if (isRefinanciacion) {
    return (
      <>
        <Typography>
          {dataset.nombre} {dataset.segundoNombre}, seleccionaste refinanciar un
          monto de{" "}
          {dataset.monedaExpresion} {currencyFormat(dataset.monto)} a pagar en{" "}
          <b>{dataset.cuotas || dataset.lastCuota}</b> cuotas de{" "}
          <b>
            {dataset.monedaExpresionCuota} {currencyFormat(dataset.montoCuota)}
          </b>
          .
          <br />
          Deberas firmar la documentación en <b>{dataset.opcion}</b> y la primera cuota vence
          el: <b>{moment(dataset.primerVencimiento).format("DD/MM/YYYY")}</b>.
        </Typography>
      </>
    );
  }
  if (isAmpliacion) {
    return (
      <Typography>
        {dataset.nombre} {dataset.segundoNombre}, seleccionaste{" "}
        {dataset.monedaExpresion} {currencyFormat(dataset.monto)} que se suman
        al monto de cancelación del vale vigente de {dataset.monedaExpresion}{" "}
        {currencyFormat(dataset.montoHeredado)} y que conjuntamente se pagarán
        en <b>{dataset.cuotas || dataset.lastCuota}</b> cuotas de{" "}
        <b>
          {dataset.monedaExpresionCuota} {currencyFormat(dataset.montoCuota)}
        </b>
        .
        <br />
        Podrás retirar los {dataset.monedaExpresion}{" "}
        {currencyFormat(dataset.monto)} en <b>{dataset.opcion}</b> y la primera
        cuota vence el:{" "}
        <b>{moment(dataset.primerVencimiento).format("DD/MM/YYYY")}</b>.
      </Typography>
    );
  }
  return (
    <Typography>
      {dataset.nombre} {dataset.segundoNombre}, seleccionaste{" "}
      {dataset.monedaExpresion} {currencyFormat(dataset.monto)} a pagar en{" "}
      <b>{dataset.cuotas || dataset.lastCuota}</b> cuotas de{" "}
      <b>
        {dataset.monedaExpresionCuota} {currencyFormat(dataset.montoCuota)}
      </b>
      .
      <br />
      <RetiroFirmaDeDinero dataset={dataset} /> en <b>{dataset.opcion}</b> y la
      primera cuota vence el:{" "}
      <b>{moment(dataset.primerVencimiento).format("DD/MM/YYYY")}</b>.
    </Typography>
  );
}

function RetiroFirmaDeDinero({ dataset }) {
  const isPasarela = dataset.canal === "PASARELA";
  if (isPasarela) return <>Deberas firmar la documentación</>;
  return <>Podras retirarlo</>;
}
