import React from "react";
import { Grid, Box, useTheme } from "@mui/material";

function Side({ displaySide, side }) {
  const theme = useTheme();
  const [show, setShow] = React.useState(false);
  const [hide, setHide] = React.useState(false);

  React.useEffect(() => {
    if (side) {
      setTimeout(() => {
        setShow(displaySide);
      }, 800);
    }
  }, [displaySide, side]);

  React.useEffect(() => {
    if (side) {
      if (displaySide && !show) {
        setHide(false);
      }
      if (!displaySide && show) {
        setTimeout(() => {
          setHide(displaySide);
        }, 800);
      }
      if (!displaySide && !show) {
        setHide(true);
      }
    } else {
      setHide(true);
    }
  }, [displaySide, show, side]);

  return (
    <Grid
      item
      xs={0}
      sm={0}
      lg={show ? 4 : 0}
      sx={{
        display: {
          xs: "none",
          sm: "none",
          lg: hide ? "none" : "block",
        },
        visibility: {
          xs: "none",
          sm: "none",
          lg: show ? "visible" : "hidden",
        },
        opacity: {
          xs: "0",
          sm: "0",
          lg: show ? "1" : "0",
        },
        transition: "visibility 0s, opacity 0.5s linear",
      }}
      style={{
        transition: theme.transitions.create("all", {
          easing: theme.transitions.easing.sharp,
          duration: "800ms",
        }),
      }}
    >
      <Box
        sx={{
          height: "60rem",
          width: "100%",
        }}
      >
        <Box
          sx={{
            marginTop: "10rem",
            backgroundImage: "url(./assets/backgrounds/finmutual_side.webp)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "fill",
            height: "50rem",
            width: "410px",
          }}
        />
      </Box>
    </Grid>
  );
}

export default Side;
