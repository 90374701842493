import RefreshRounded from "@mui/icons-material/RefreshRounded";
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { FaAddressCard, FaFingerprint, FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { resetFr } from "../../../../services/steps.services";
import { SelectInput } from "../../../Inputs";
import ShowAlert from "./Alerts/ShowAlert";
import ShowError from "./Alerts/ShowError";
import PicButtons from "./Buttons/PicButtons";
import UploadButton from "./Buttons/UploadButton";
import Camera from "./Camera/Camera";
import { useCamera, usePermissions } from "./hooks";
import Loading from "./Loading/Loading";

const StepFR = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dataset = useSelector((state) => state.config.dataset);
  const stepConf = useSelector((state) => state.config.step);
  const jumpData = useSelector((state) => state.config.jumpData);
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    permissions,
    loading,
    devices,
    selectedDevice,
    handlePermission,
    setSelectedDevice,
    getVideoDevices,
  } = usePermissions();

  const {
    submit,
    setSnapshots,
    snapshots,
    setTakeAgain,
    takeAgain,
    openModal,
    openAlert,
    intentos,
    setOpenError,
    openError,
    closeAlert,
  } = useCamera();

  React.useEffect(() => {
    handlePermission(dataset.solicitudTipo);
    if (!stepConf.showFaceRecognition) {
      if (activeStep === maxStep) {
        handleMainNextStep();
      } else {
        handleNextStep();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!intentos >= 1) {
      dispatch({ type: "hide/questions" });
      dispatch({
        type: "show/help",
        payload: {
          helpTitle: "No pudimos identificarte",
          helpBody: "Un agente se contactara a la brevedad",
          showButton: false,
        },
      });
    }
    // eslint-disable-next-line
  }, [intentos]);

  React.useEffect(() => {
    if (jumpData.token !== "") {
      resetFr(
        dataset.id,
        dataset.cedula,
        dataset.telefono,
        dataset.canal,
        dataset.sucursal,
        jumpData.token
      );
    }
    // eslint-disable-next-line
  }, [jumpData.token]);

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = (e) => {
    submit(e, handleNextStep, handleMainNextStep, activeStep, maxStep);
  };

  const SelectDevices = () => {
    return (
      <Stack direction="row" spacing={1}>
        {devices.length > 0 && selectedDevice && (
          <>
            <SelectInput
              select
              value={selectedDevice}
              onChange={(e) => setSelectedDevice(e.target.value)}
              size="small"
              fullWidth
              items={devices.map((item, i) => (
                <MenuItem value={item} key={item.deviceId}>
                  {item.label ? item.label : `Camara ${i + 1}`}
                </MenuItem>
              ))}
            />

            <IconButton onClick={getVideoDevices}>
              <RefreshRounded />
            </IconButton>
          </>
        )}
      </Stack>
    );
  };

  const [imagesStatus, setImagesStatus] = useState({
    frontal: true,
    reverso: true,
    spy: true,
  });

  if (!stepConf.showFaceRecognition) {
    return <Box />;
  }
  return (
    <>
      {loading ? (
        <Stack
          direction={fullScreen ? "column" : "row"}
          spacing={2}
          style={{ width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {!permissions &&
          (dataset.canal === "FISICO" ||
            dataset.canal === "SOLICITUD_WEB" ||
            dataset.canal === "PASARELA") ? (
            <Stack spacing={2}>
              <Alert severity="error">
                Porfavor permita el acceso a la camara para continuar, si tiene
                problemas, contacte al administrador.
              </Alert>
              <Button
                onClick={handlePermission}
                variant="contained"
                color="secondary"
                size="small"
              >
                Comprobar permisos
              </Button>
            </Stack>
          ) : (
            <>
              <Loading openModal={openModal} />
              <ShowAlert
                openAlert={openAlert}
                closeAlert={closeAlert}
                intentos={intentos}
              />
              <Box
                component="form"
                display="flex"
                flexDirection="column"
                gap={4}
                onSubmit={(e) => handleSubmit(e)}
              >
                <Stack direction="column" spacing={2} style={{ width: "100%" }}>
                  {((dataset.canal === "FISICO" ||
                    dataset.canal === "SOLICITUD_WEB") &&
                    jumpData.token === "") ||
                  dataset.canal === "PASARELA" ? (
                    <Stack
                      direction={fullScreen ? "column" : "row"}
                      spacing={2}
                      style={{ width: "100%" }}
                    >
                      <Camera
                        onCapture={(file) =>
                          setSnapshots({ ...snapshots, frontal: file[0] })
                        }
                        title="Subí aquí una foto del frente de tu CI"
                        icon={
                          <FaAddressCard
                            style={{
                              fontSize: "4rem",
                              color: theme.palette.primary.main,
                              width: "130px",
                              height: "100%",
                            }}
                          />
                        }
                        device={selectedDevice}
                        takeAgain={takeAgain}
                        deviceList={devices}
                        setDevice={setSelectedDevice}
                        convertToBlob={true}
                        setStatus={(value) =>
                          setImagesStatus((prev) => ({
                            ...prev,
                            frontal: value,
                          }))
                        }
                      />

                      <Camera
                        onCapture={(file) =>
                          setSnapshots({ ...snapshots, reverso: file[0] })
                        }
                        title="Subí aquí una foto del dorso de tu CI"
                        icon={
                          <FaFingerprint
                            style={{
                              fontSize: "4rem",
                              color: theme.palette.primary.main,
                              width: "130px",
                              height: "100%",
                            }}
                          />
                        }
                        device={selectedDevice}
                        takeAgain={takeAgain}
                        deviceList={devices}
                        setDevice={setSelectedDevice}
                        convertToBlob={true}
                        setStatus={(value) =>
                          setImagesStatus((prev) => ({
                            ...prev,
                            reverso: value,
                          }))
                        }
                      />

                      <Camera
                        onCapture={(file) =>
                          setSnapshots({ ...snapshots, selfie: file })
                        }
                        captures={4}
                        title="Subí aquí una foto en modo selfie"
                        icon={
                          <FaUserCircle
                            style={{
                              fontSize: "4rem",
                              color: theme.palette.primary.main,
                              width: "130px",
                              height: "100%",
                            }}
                          />
                        }
                        device={selectedDevice}
                        takeAgain={takeAgain}
                        deviceList={devices}
                        setDevice={setSelectedDevice}
                        convertToBlob={true}
                        setStatus={(value) =>
                          setImagesStatus((prev) => ({
                            ...prev,
                            spy: value,
                          }))
                        }
                      />
                    </Stack>
                  ) : (
                    <Stack
                      direction={fullScreen ? "column" : "row"}
                      spacing={2}
                      style={{ width: "100%" }}
                    >
                      <Stack
                        direction="column"
                        spacing={1}
                        style={{ width: "100%" }}
                      >
                        <UploadButton
                          label="Subí aquí una foto del frente de tu CI"
                          onChange={(value) => {
                            value !== null &&
                              setSnapshots({ ...snapshots, frontal: value });
                          }}
                          maxSize={5000000}
                          extensions={["jpg", "jpeg", "png"]}
                        />
                      </Stack>
                      <Stack
                        direction="column"
                        spacing={1}
                        style={{ width: "100%" }}
                      >
                        <UploadButton
                          label="Subí aquí una foto del dorso de tu CI"
                          onChange={(value) => {
                            value !== null &&
                              setSnapshots({ ...snapshots, reverso: value });
                          }}
                          maxSize={5000000}
                          extensions={["jpg", "jpeg", "png"]}
                        />
                      </Stack>

                      <Stack
                        direction="column"
                        spacing={1}
                        style={{ width: "100%" }}
                      >
                        <Stack
                          direction="column"
                          spacing={1}
                          style={{ width: "100%" }}
                        >
                          <UploadButton
                            label="Selfie 1"
                            onChange={(value) => {
                              value !== null &&
                                setSnapshots({
                                  ...snapshots,
                                  selfie: [...snapshots.selfie, value],
                                });
                            }}
                            maxSize={5000000}
                            extensions={["jpg", "jpeg", "png"]}
                          />
                          <UploadButton
                            label="Selfie 2"
                            onChange={(value) => {
                              value !== null &&
                                setSnapshots({
                                  ...snapshots,
                                  selfie: [...snapshots.selfie, value],
                                });
                            }}
                            maxSize={5000000}
                            extensions={["jpg", "jpeg", "png"]}
                          />
                          <UploadButton
                            label="Selfie 3"
                            onChange={(value) => {
                              value !== null &&
                                setSnapshots({
                                  ...snapshots,
                                  selfie: [...snapshots.selfie, value],
                                });
                            }}
                            maxSize={5000000}
                            extensions={["jpg", "jpeg", "png"]}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                  {dataset.solicitudTipo === "YA_FIRMADA" &&
                    dataset.canal !== "SOLICITUD_WEB" &&
                    jumpData.token === "" && <SelectDevices />}
                </Stack>
                <PicButtons
                  setTakeAgain={setTakeAgain}
                  takeAgain={takeAgain}
                  disableContinue={
                    Object.values(imagesStatus).includes(false) ||
                    !(
                      snapshots.frontal !== null &&
                      snapshots.reverso !== null &&
                      snapshots.selfie.length >= 3
                    )
                  }
                  disableTakeAgain={
                    snapshots.frontal === null &&
                    snapshots.reverso === null &&
                    snapshots.selfie.length === 0
                  }
                />
                <ShowError openError={openError} setOpenError={setOpenError} />
              </Box>
            </>
          )}
          {Object.values(imagesStatus).includes(false) && (
            <Stack spacing={2} marginTop={2}>
              <Alert severity="error">
                Alguna de sus fotos no se ha tomado correctamente.
                <br />
                Verifique que la cámara no este capturada por otra aplicación.
                <br />
                Y/o pueda ver la imagen correcta en la ventana emergente.
              </Alert>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default StepFR;
