import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tries: 1,
  isRecaptchaOn: false,
};

const formTriesSlice = createSlice({
  name: "tries",
  initialState,
  reducers: {
    addTry: (state, action) => {
      state.tries += 1;
      state.isRecaptchaOn = state.tries > 3;
    },
    reset: (state, action) => {
      state.tries = 1;
      state.isRecaptchaOn = false;
    },
  },
});

export const formTriesActions = formTriesSlice.actions;

export default formTriesSlice.reducer;
