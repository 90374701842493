import { Button, Link, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RedirectToOrderPage = ({ secondsToRedirect = 60 * 5, error = false }) => {
  // No mostrar el componente si no existe pasarelaWebhookUrl
  const {
    cedula,
    pasarelaWebhookUrl,
    pasarelaName,
    pasarelaSecondsToBackOnError,
    pasarelaSecondsToBackOnSuccess,
  } = useSelector((state) => state.config.dataset);

  const [secondsRemaining, setSecondsRemaining] = useState(
    getSeconds(
      secondsToRedirect,
      error,
      pasarelaSecondsToBackOnError,
      pasarelaSecondsToBackOnSuccess
    )
  );

  // Nombre de la pasarela a mostrar
  const pasarelaNameToShow = pasarelaName
    ? pasarelaName
    : pasarelaWebhookUrl?.replace(/https?:\/\//, "");
  const hasPasarelaWebhookUrl = !!pasarelaWebhookUrl;

  // URL de redirección
  let webhookUrl = pasarelaWebhookUrl;
  const hash = getHash(cedula);
  const successMethod = process.env.REACT_APP_EXTERNAL_CALLBACK_METHOD_SUCCESS;
  const errorMethod = process.env.REACT_APP_EXTERNAL_CALLBACK_METHOD_ERROR;
  if (error && errorMethod) webhookUrl = webhookUrl + errorMethod + `/${hash}`;
  else if (successMethod) webhookUrl = webhookUrl + successMethod + `/${hash}`;

  // Lógica de redirección automática con contador
  useEffect(() => {
    if (!hasPasarelaWebhookUrl) return;

    if (secondsRemaining > 0) {
      const timer = setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      window.location.href = pasarelaWebhookUrl;
    }
  }, [secondsRemaining, pasarelaWebhookUrl]);

  if (!hasPasarelaWebhookUrl) return null;
  return (
    <Stack alignItems={"center"} spacing={2}>
      <p className="text-gray-600 text-center">
        Redirigiéndote a {pasarelaNameToShow} en {secondsRemaining} segundos...
      </p>

      <Button
        LinkComponent={Link}
        sx={{ mt: 1 }}
        variant="contained"
        href={webhookUrl}
        target="_self"
        rel="noopener noreferrer"
      >
        Volver a {pasarelaNameToShow}
      </Button>
    </Stack>
  );
};

export default RedirectToOrderPage;

/**
 * Esta función extrae el hash que aparece después del número de documento en la URL.
 * El hash se encuentra en el parámetro "pasarela", que tiene un formato similar a:
 * "5372-26030976-711f2774-8c59-430d-ba46-2aac46c95630".
 * Lo que nos interesa es obtener todo lo que aparece después del número de documento
 * (por ejemplo, "711f2774-8c59-430d-ba46-2aac46c95630").
 *
 * @param {string} documento - El número de documento que se utiliza como referencia en la URL.
 * @returns {string|null} - El hash que aparece después del documento, o null si no se encuentra.
 */
function getHash(documento) {
  const urlParams = new URLSearchParams(window.location.search);
  let pasarela = urlParams.get("pasarela");

  let res = null;
  if (pasarela) {
    // Encuentra la posición del número de documento
    const index = pasarela.indexOf(documento);

    if (index !== -1) {
      // Extrae todo después del documento
      const hash = pasarela.substring(index + documento.length + 1); // +1 para omitir el guion después del documento
      res = hash;
    }
  }
  return res;
}

/**
 * Esta función calcula los segundos restantes para redirigir a la pasarela.
 * Si el error es verdadero, se redirige en base a los segundos de error.
 * Si el error es falso, se redirige en base a los segundos de éxito.
 *
 * @param {number} secondsToRedirect - Los segundos totales para redirigir a la pasarela.
 * @param {boolean} error - Si ocurrió un error en la transacción.
 * @param {number} secondsOnError - Los segundos para redirigir en caso de error.
 * @param {number} secondsOnSuccess - Los segundos para redirigir en caso de éxito.
 * @returns {number} - Los segundos restantes para redirigir a la pasarela.
 */
function getSeconds(
  secondsToRedirect,
  error,
  secondsOnError,
  secondsOnSuccess
) {
  const res = error ? secondsOnError : secondsOnSuccess;
  return res ? res : secondsToRedirect;
}
