import { TextField } from "@mui/material";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  onTouchStart: (event) => {
    event.stopPropagation();
  },
};

function SelectInput({
  items,
  id,
  name,
  label,
  value,
  onChange,
  error,
  disabled,
  fullWidth,
  size,
  required,
}) {
  return (
    <TextField
      id={id}
      select
      disabled={disabled}
      required={required}
      name={name}
      label={label}
      fullWidth={fullWidth}
      SelectProps={{ MenuProps: MenuProps }}
      value={value ? value : ""}
      onChange={onChange}
      error={error}
      size={size}
    >
      {items}
    </TextField>
  );
}

export default SelectInput;
