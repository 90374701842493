import React from "react";
import { Snackbar, Alert } from "@mui/material";
function ShowAlert({ openAlert, closeAlert, intentos }) {
  return (
    <Snackbar open={openAlert} autoHideDuration={6000} onClose={closeAlert}>
      <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
        {intentos > 0
          ? `No hemos podido verificar tu identidad, tienes ${intentos} ${
              intentos > 1 ? "intentos" : "intento"
            } ${intentos > 1 ? "restantes" : "restante"}.`
          : "No hemos podido verificar tu identidad"}
      </Alert>
    </Snackbar>
  );
}

export default ShowAlert;
