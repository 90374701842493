import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepTyCIntermedio } from "../../../services/steps.services";
import { errorSetter } from "../../../utils";
import Error from "../../Warnings/Error";

/**
 * This step is used to generate TyC Intermedio, don't show anything
 * @param {*} param0
 * @returns
 */
const StepTyCIntermedio = ({
  handleNextStep,
  handleBackStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dispatch = useDispatch();
  const dataset = useSelector((state) => state.config.dataset);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorText, setErrorText] = useState("Ha ocurrido un error");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    setOpenAlert(false);
    setIsLoading(true);
    dispatch({ type: "show/spinner" });
    e?.preventDefault();
    stepTyCIntermedio(
      dataset.canal,
      dataset.id,
      dataset.cedula,
      dataset.telefono,
      dataset.sucursal,
      dataset.token
    )
      .then((response) => {
        if (response.data.productoFinanciero.sendForDigitalSignature) {
          dispatch({ type: "step/two-b/remote" });
        }
        dispatch({
          type: "step/two-b",
          payload: {
            urlTerminos: response.data.terminosYCondicionesHTML || null,
          },
        });
        dispatch({ type: "show/spinner" });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
      })
      .catch((error) => {
        console.log(error);
        errorSetter(setOpenAlert, setErrorText, error);
        dispatch({ type: "hide/spinner" });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" rowSpacing={4}>
      <Grid item xs={12} md={8}>
        <Typography>
          Sí el error persiste, en la brevedad nos comunicaremos con usted.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={4}
        >
          <Button
            variant="contained"
            type="button"
            disabled={isLoading}
            onClick={handleSubmit}
            color="button"
          >
            Continuar
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
      </Grid>
    </Grid>
  );
};

export default StepTyCIntermedio;
