import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Typography, useTheme } from "@mui/material";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 24,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    marginLeft: 15,
    marginRight: 15,
    backgroundColor: theme.palette.primary.backgroundStepColor,
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  border: "2px solid #fff",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props) {
  const theme = useTheme();
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      style={{
        backgroundColor: active
          ? theme.palette.primary.backgroundStepColor
          : "transparent",
      }}
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? (
        <Check
          style={{ color: theme.palette.primary.completedColor }}
          className="QontoStepIcon-completedIcon"
        />
      ) : (
        <Typography
          style={{
            color: active
              ? theme.palette.primary.completedColor
              : theme.palette.primary.numColor,
            fontWeight: "600",
          }}
        >
          {String(props.icon)}
        </Typography>
      )}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export { ColorlibConnector, ColorlibStepIcon };
