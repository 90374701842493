import Close from "@mui/icons-material/Cancel";
import {
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Radio,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  stepGetQuestions,
  stepSendAnswers,
} from "../../../services/steps.services";
import { showEmotions } from "../../../utils";
import ShowError from "./StepFR/Alerts/ShowError";

const StepThreeB = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dataset = useSelector((state) => state.config.dataset);
  const stepConf = useSelector((state) => state.config.step);
  const dispatch = useDispatch();
  const [preguntasYRespuestas, setPreguntasYRespuestas] = React.useState([]);
  const [checked, setChecked] = React.useState(true);
  const [disabled, setDisabled] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  React.useEffect(() => {
    if (!stepConf.showIdValidator || dataset.frValidated) {
      if (activeStep === maxStep) {
        handleMainNextStep();
      } else {
        handleNextStep();
      }
    } else {
      setLoading(true);
      stepGetQuestions(
        dataset.id,
        dataset.sucursal,
        dataset.canal,
        dataset.cedula,
        dataset.telefono
      )
        .then((response) => {
          setLoading(false);
          setSuccess(true);
          setPreguntasYRespuestas(response.data.peticion.idValidator.preguntas);
          dispatch({ type: "hide/spinner" });
        })
        .catch((err) => {
          setLoading(false);
          dispatch({ type: "hide/spinner" });
          try {
            const { returnCode } = err.response.data;
            const { data } = err.response.data;
            if (
              (returnCode && returnCode.includes("BL.found_record")) ||
              (data.returnCode && data.returnCode.includes("BL.found_record"))
            ) {
              dispatch({
                type: "show/error",
                payload: {
                  errorTitle: "Sin ofertas",
                  errorBody: dataset.sinOfertasText,
                },
              });
            } else {
              dispatch({
                type: "show/error",
                payload: {
                  errorTitle: "Ha ocurrido un error",
                  errorBody: "No hemos podido continuar tu solicitud",
                },
              });
            }
          } catch {
            dispatch({
              type: "show/error",
              payload: {
                errorTitle: "Ha ocurrido un error",
                errorBody: "No hemos podido continuar tu solicitud",
              },
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const contestarPregunta = (indexPregunta, indexRespuesta) => {
    const preguntasToUpdate = preguntasYRespuestas;

    preguntasToUpdate[indexPregunta].respuestas = preguntasToUpdate[
      indexPregunta
    ].respuestas.map((item, index) =>
      index === indexRespuesta
        ? { ...item, seleccionada: true }
        : { ...item, seleccionada: false }
    );
    return setPreguntasYRespuestas([...preguntasToUpdate]);
  };

  const handleSubmit = () => {
    setOpenError(false);
    const obj = {
      idValidator: { preguntas: preguntasYRespuestas },
    };
    dispatch({ type: "show/spinner" });
    setLoading(true);
    stepSendAnswers(
      dataset.id,
      dataset.sucursal,
      dataset.canal,
      dataset.cedula,
      dataset.telefono,
      obj
    )
      .then((response) => {
        setLoading(false);
        dispatch({ type: "show/spinner" });
        if (response.data.ivValidated) {
          if (showEmotions(dataset.sucursal)) {
            setTimeout(() => {
              dispatch({ type: "hide/emotions" });
            }, 4500);
            dispatch({ type: "show/veryHappyFace" });
          }
          if (activeStep === maxStep) {
            handleMainNextStep();
          } else {
            handleNextStep();
          }
        } else {
          if (
            response.data.pffIvIntentosConAsesor >
              response.data.intentosIVConAsesor ||
            !response.data.intentosIVConAsesor
          ) {
            setOpenError(true);
          } else {
            dispatch({
              type: "show/error",
              payload: {
                errorTitle: "No se ha podido validar tu identidad.",
                errorBody: "Un administrador se contactara a la brevedad",
              },
            });
          }
          if (
            response.data.pffIvIntentos > response.data.intentosIV ||
            !response.data.intentosIV
          ) {
            setOpenError(true);
          } else {
            dispatch({
              type: "show/error",
              payload: {
                errorTitle: "No se ha podido validar tu identidad.",
                errorBody: "Un administrador se contactara a la brevedad",
              },
            });
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({ type: "show/spinner" });
        dispatch({
          type: "show/error",
          payload: {
            errorTitle: "No se ha podido validar tu identidad.",
            errorBody: "Un administrador se contactara a la brevedad",
          },
        });
      });
  };

  React.useEffect(
    () => {
      const preguntasRespondidas = preguntasYRespuestas.filter((item) =>
        item.respuestas.some((item) => item.seleccionada)
      );
      if (
        preguntasRespondidas.length === preguntasYRespuestas.length &&
        success
      ) {
        setDisabled(false);
      }
    },
    //eslint-disable-next-line
    [preguntasYRespuestas]
  );

  if (!stepConf.showIdValidator || dataset.frValidated) {
    return <Box />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Collapse in={checked}>
        <Typography variant="h6" textAlign="center">
          Para tu seguridad y la nuestra, necesitamos que nos contestes algunas
          preguntas sobre tus antecedentes financieros.
          <IconButton
            onClick={() => setChecked(!checked)}
            aria-label="question"
          >
            <Close />
          </IconButton>
        </Typography>
      </Collapse>
      {loading ? (
        <Box sx={{ marginY: "2rem" }}>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Cargando...
          </Typography>
        </Box>
      ) : (
        <>
          {preguntasYRespuestas.map((pregunta, i) => (
            <Paper key={i} sx={{ marginX: "2rem" }} elevation={1}>
              <Typography sx={{ marginX: "1rem", marginY: "1rem" }}>
                {pregunta.text}
              </Typography>
              <FormGroup>
                {pregunta.respuestas.map((respuesta, e) => (
                  <FormControlLabel
                    key={e}
                    sx={{ marginX: "1rem" }}
                    control={
                      <Radio
                        checked={respuesta.seleccionada}
                        onChange={() => contestarPregunta(i, e)}
                      />
                    }
                    label={respuesta.text}
                  />
                ))}
              </FormGroup>
            </Paper>
          ))}
        </>
      )}

      <Button
        disabled={disabled}
        variant="contained"
        color="button"
        sx={{ width: "min-content", alignSelf: "flex-end" }}
        onClick={() => handleSubmit()}
      >
        Continuar
      </Button>
      <ShowError openError={openError} setOpenError={setOpenError} />
    </Box>
  );
};

export default StepThreeB;
