import axios from "axios";
import CryptoJS from "crypto-js";

const getEnum = async (token, url, setter) => {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_API_URL + url,
    headers: token && { Authorization: token },
  })
    .then((response) => setter(response.data))
    .catch((err) => console.log(err));
};
const getIp = async (setter, setErr) => {
  return axios({
    method: "GET",
    url: "https://ipapi.co/json/",
  })
    .then((response) => {
      const encodedStructure = CryptoJS.AES.encrypt(
        JSON.stringify(response.data),
        process.env.REACT_APP_SECRET_FOR_IP
      );
      const encode = encodedStructure.toString();

      setter(encode);
    })
    .catch((err) => {
      console.log(err);
      setErr(true);
    });
};

export { getEnum, getIp };
