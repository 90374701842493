import ErrorIcon from "@mui/icons-material/Error";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";

const Help = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: "2rem",
      fontWeight: 700,
      textAlign: "center",
    },
    body: {
      fontSize: "1rem",
      textAlign: "center",
    },
    icon: {
      fontSize: "5rem",
    },
  };
  const dataset = useSelector((state) => state.config.dataset);
  return (
    <Box sx={styles.container} gap={4}>
      <Typography sx={styles.title}>{dataset.helpTitle}</Typography>
      <ErrorIcon sx={styles.icon} />
      <Typography sx={styles.body}>{dataset.helpBody}</Typography>

      {dataset.myAccount && dataset.showButton && (
        <Button
          variant="contained"
          color="button"
          sx={{ alignSelf: "center", marginY: "2rem" }}
          onClick={() => window.open(dataset.myAccount, "_self")}
        >
          Ir a mi cuenta
        </Button>
      )}
    </Box>
  );
};

export default Help;
