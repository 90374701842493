import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Slider,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { TextInput } from "../../../Inputs";
import BasicCard from "./BasicCard";
import { currencyFormat } from "../../../../utils";

const valueErrors = {
  unKnown: (validValue, slot) =>
    `El monto no es válido, por ejemplo ingresá: ${validValue}, elegí múltiplos de ${slot}`,
  min: (min) => `El monto no puede ser menor a ${min}`,
  max: (max) => `El monto no puede ser mayor a ${max}`,
};

function BasicSlider({
  oferta,
  handleSeleccionarOferta,
  handleChangeMonto,
  readOnly,
}) {
  // eslint-disable-next-line
  const dataset = useSelector((state) => state.config.dataset);
  // const main = oferta.main;

  // Si tiene slot, que son los tramos en los que se elije el monto, se calcula el máximo y mínimo
  const maximo = dataset.slot
    ? Math.floor(oferta.maximoMonto / dataset.slot) * dataset.slot
    : oferta.maximoMonto;
  const minimo = dataset.slot
    ? Math.ceil(oferta.minimoMonto / dataset.slot) * dataset.slot
    : oferta.minimoMonto;
  const moneda = oferta.monedaExpresionName;
  const [value, setValue] = React.useState(maximo);

  const handleClick = () => {
    const valSelected = getCorrectValue(value);
    setValue(valSelected);
    handleSeleccionarOferta(oferta, valSelected);
  };

  const calculate = (tipoCalculo, value) => {
    const rounder = (e) => {
      if (tipoCalculo === "CEILING") {
        return Math.ceil(e);
      }
      return Math.round(e);
    };

    if (rounder(value / dataset.slot) * dataset.slot < minimo) {
      return minimo;
    }

    if (rounder(value / dataset.slot) * dataset.slot > maximo) {
      return maximo;
    }

    const newVal = rounder(value / dataset.slot) * dataset.slot;
    return newVal;
  };

  const getCorrectValue = (val) => {
    handleChangeMonto();
    if (val < minimo) {
      return minimo;
    }
    if (dataset.slot) {
      if (dataset.tipoDeCalculo) {
        return calculate(dataset.tipoDeCalculo, val);
      }
      return calculate("ROUNDED", val);
    }
    return val;
  };

  const [errorValue, setErrorValue] = React.useState(null);
  // Handle errors value
  React.useEffect(() => {
    if (isNaN(value))
      return setErrorValue(valueErrors.unKnown(maximo, dataset.slot));

    if (value.toString() < minimo)
      return setErrorValue(valueErrors.min(minimo));
    else if (value.toString() > maximo)
      return setErrorValue(valueErrors.max(maximo));

    const correctValue = getCorrectValue(value);
    if (correctValue.toString() !== value.toString())
      return setErrorValue(valueErrors.unKnown(correctValue, dataset.slot));

    return setErrorValue(null);
    // eslint-disable-next-line
  }, [value]);

  if (oferta.maximoMonto < dataset.slot) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5">Oferta no disponible</Typography>
      </Box>
    );
  }

  if (minimo > maximo) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5">Oferta no disponible</Typography>
      </Box>
    );
  }

  if (minimo === maximo) {
    return (
      <BasicCard
        oferta={{ ...oferta, minimoMonto: minimo, maximoMonto: maximo }}
        handleSeleccionarOferta={handleSeleccionarOferta}
      />
    );
  }

  return (
    <Card
      sx={{
        width: "100%",
        minWidth: 275,
        border: oferta.seleccionado ? "3px solid" : "0px solid transparent",
        borderColor: oferta.seleccionado ? "primary.main" : "transparent",
      }}
      elevation={3}
    >
      <CardContent>
        <Grid
          container
          sx={{
            paddingX: { xs: "1rem", sm: "1rem", md: "4rem" },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginY: "1rem",
            }}
          >
            <Typography sx={{ fontSize: 14 }}>{moneda}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box>
              <FormControl fullWidth error={!!errorValue}>
                <TextInput
                  error={!!errorValue}
                  label="Monto exacto"
                  onChange={(e) => {
                    setValue(e.target.value);
                    handleChangeMonto();
                  }}
                  disabled={readOnly}
                  value={value}
                />

                {errorValue && <FormHelperText>{errorValue}</FormHelperText>}
                {!errorValue && dataset.slot && !readOnly && (
                  <FormHelperText error={false}>
                    Elegí múltiplos de {dataset.slot}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Grid>

          {!isNaN(value) && oferta.cotizacion && oferta.cotizacion !== 1 && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Typography sx={{ fontSize: 13 }} color="text.secondary">
                ({dataset.monedaNacional}{" "}
                {currencyFormat(Number(value) * Number(oferta.cotizacion))})
              </Typography>
            </Grid>
          )}
        </Grid>

        {!readOnly && (
          <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>{minimo}</Typography>
              <Typography>{maximo}</Typography>
            </Box>
            <Box sx={{ marginX: "0.5rem" }}>
              <Slider
                min={minimo}
                max={maximo}
                value={Number(value)}
                onChange={(e) => setValue(getCorrectValue(e.target.value))}
                aria-label="Monto"
                valueLabelDisplay="auto"
              />
            </Box>
          </Box>
        )}
      </CardContent>
      {!readOnly && (
        <CardActions sx={{ justifyContent: "end" }}>
          <Button
            className="button"
            variant="contained"
            size="small"
            color="button"
            disabled={errorValue}
            onClick={() => handleClick()}
          >
            Seleccionar
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default BasicSlider;
