import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { prevRequestActions } from "../../../services/reducers/configReducer/prevRequestSlice";
import { stepThreeA } from "../../../services/steps.services";
import { currencyFormat, errorSetter } from "../../../utils";
import { TextInput } from "../../Inputs";
import Error from "../../Warnings/Error";
import useIsSoliciudRefinanciacion from "../../../hooks/useIsSolicitudRefinanciacion";
import useIsSolicitudAmpliacion from "../../../hooks/useIsSolicitudAmpliacion";
import Adicionales from "./Adicionales";

const StepTwoA = ({
  handleNextStep,
  activeStep,
  maxStep,
  handleMainNextStep,
}) => {
  const isRefinanciacion = useIsSoliciudRefinanciacion();
  const isAmpliacion = useIsSolicitudAmpliacion();
  const theme = useTheme();
  const dispatch = useDispatch();
  const dataset = useSelector((state) => state.config.dataset);
  const prevRequestStore = useSelector((state) => state.prevRequest);
  const [formData, setFormData] = useState({
    ...getInitialValues(dataset, prevRequestStore),
    aceptaTyC: false,
  });
  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorText, setErrorText] = React.useState("Ha ocurrido un error");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenAlert(false);
    if (
      formData.direccion === "" ||
      formData.aceptaTyC === false ||
      formData.direccion.length < 10
    ) {
      return;
    } else {
      dispatch({ type: "show/spinner" });

      dispatch(
        prevRequestActions.update({
          cedula: dataset.cedula,
          direccion: formData.direccion,
        })
      );

      stepThreeA(
        dataset.canal,
        dataset.id,
        formData.direccion,
        formData.aceptaTyC,
        dataset.cedula,
        dataset.telefono,
        dataset.sucursal,
        dataset.token,
        dataset.tokenRemote
      )
        .then((response) => {
          dispatch({ type: "hide/spinner" });
          if (activeStep === maxStep) {
            handleMainNextStep();
          } else {
            handleNextStep();
          }
        })
        .catch((error) => {
          console.log(error);
          errorSetter(setOpenAlert, setErrorText, error);
          dispatch({ type: "hide/spinner" });
        });
    }
  };

  const hasAdicionales = dataset.adicionales?.selected?.length;
  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={4}
      onSubmit={(e) => handleSubmit(e)}
    >
      <Typography variant="h5">
        {dataset.nombre} {dataset.segundoNombre} el préstamo casi es tuyo...
      </Typography>
      <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ padding: { xs: "1rem", sm: "2rem", md: "2rem", lg: "3rem" } }}
            gap={2}
          >
            <Box display="flex" flexDirection="row" gap={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                sx={{ width: "100%" }}
              >
                <Typography>
                  {isRefinanciacion
                    ? "Monto a refinanciar"
                    : isAmpliacion
                    ? "Monto ampliado a cobrar"
                    : "Monto"}
                </Typography>
                <Typography fontWeight="bold">
                  {dataset.monedaExpresion} {currencyFormat(dataset.monto)}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                sx={{ width: "100%" }}
              >
                <Typography>Departamento</Typography>
                <Typography fontWeight="bold">
                  {dataset.departamento.label || dataset.departamento.nombre}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" gap={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                sx={{ width: "100%" }}
              >
                <Typography>
                  Cuotas {hasAdicionales ? "(incluye adicionales)" : ""}
                </Typography>
                <Typography fontWeight="bold">
                  {dataset.cuotas} x {dataset.monedaExpresionCuota}{" "}
                  {!hasAdicionales
                    ? currencyFormat(dataset.montoCuota)
                    : currencyFormat(
                        dataset.adicionales.montoCuotaConAdicionales
                      )}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                sx={{ width: "100%" }}
              >
                <Typography>Localidad</Typography>
                <Typography fontWeight="bold">
                  {dataset.localidad.label || dataset.localidad.nombre}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" gap={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                sx={{ width: "100%" }}
              >
                <Typography>Vto. 1 cuota</Typography>
                <Typography fontWeight="bold">
                  {moment(dataset.primerVencimiento).format("DD/MM/YYYY")}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                sx={{ width: "100%" }}
              >
                <Typography>Opción elegida</Typography>
                <Typography fontWeight="bold">{dataset.opcion}</Typography>
              </Box>
            </Box>
            <Adicionales dataset={dataset} hidden={!hasAdicionales} />
            {isAmpliacion && (
              <Box display="flex" flexDirection="row" gap={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  sx={{ width: "100%" }}
                >
                  <Typography>Monto capital total del nuevo vale</Typography>
                  <Typography fontWeight="bold">
                    {dataset.monedaExpresion}{" "}
                    {currencyFormat(dataset.monto + dataset.montoHeredado)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <Typography variant="h5">Necesitamos tu dirección</Typography>
      <Stack spacing={4} direction={{ xs: "column", sm: "row", md: "row" }}>
        <FormControl required sx={{ width: "100%" }}>
          <TextInput
            label="Dirección completa"
            value={formData.direccion}
            id="direccion"
            name="direccion"
            type="text"
            inputProps={{ minLength: 10 }}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, direccion: e.target.value }))
            }
            required
          />
        </FormControl>
      </Stack>
      <FormGroup required>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.aceptaTyC}
              style={{ color: theme.palette.primary.checkColor }}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  aceptaTyC: e.target.checked,
                }))
              }
              required
            />
          }
          label={
            <Typography fontSize="small" style={{ display: "inline" }}>
              <a
                style={{ color: "#000000" }}
                href={dataset.urlTerminos}
                target="_blank"
                rel="noreferrer"
              >
                Acepto términos y condiciones
              </a>
            </Typography>
          }
        />
      </FormGroup>
      <Button
        variant="contained"
        type="submit"
        color="button"
        sx={{ width: "min-content", alignSelf: "flex-end" }}
      >
        Continuar
      </Button>
      <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
    </Box>
  );
};

export default StepTwoA;

function getInitialValues(dataset, prevRequestStore) {
  // find request in prevRequestStored.documentos with dataset.cedula
  const request = prevRequestStore.documentos.find(
    (doc) => doc.cedula === dataset.cedula
  );

  // find first field in dataset, else find in request if exists. If not, return empty string
  const getField = (field) => {
    let res = dataset[field];
    if (!res && request) res = request[field];
    return res || "";
  };

  return {
    direccion: getField("direccion"),
  };
}
