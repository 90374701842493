import React from "react";
import { Box, Button, useTheme } from "@mui/material";
function PicButtons({
  setTakeAgain,
  takeAgain,
  disableContinue,
  disableTakeAgain,
}) {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Button
        variant="outlined"
        type="button"
        disabled={disableTakeAgain}
        sx={{
          width: "min-content",
          alignSelf: "flex-end",
          color: theme.palette.text.main,
        }}
        color="button"
        onClick={() => {
          setTakeAgain(!takeAgain);
        }}
      >
        Tomarlas nuevamente
      </Button>

      <Button
        variant="contained"
        type="submit"
        disabled={disableContinue}
        color="button"
        sx={{
          width: "min-content",
          alignSelf: "flex-end",
          color: "primary.unload",
        }}
      >
        Continuar
      </Button>
    </Box>
  );
}

export default PicButtons;
