import { Button, FormControl, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByUsername } from "../../services/auth.services";
import { SelectInput } from "../Inputs";
import Error from "../Warnings/Error";
//import OTP from "../../OTP/OTP";
const StepOneA = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    if (!dataset.operador) {
      if (activeStep === maxStep) {
        handleMainNextStep();
      } else {
        handleNextStep();
      }
    }
    if (dataset.operador && dataset.token) {
      dispatch({ type: "show/spinner" });
      getByUsername(dataset.operadorData.mail, dataset.token)
        .then((response) => {
          if (response.data.tipo.id === 11) {
            setCanSelectCanal(true);
            setShow(true);
            dispatch({ type: "hide/spinner" });
          } else {
            dispatch({ type: "hide/spinner" });
            if (activeStep === maxStep) {
              handleMainNextStep();
            } else {
              handleNextStep();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: "hide/spinner" });
        });
    }
    // eslint-disable-next-line
  }, []);
  const canales = [
    { value: "SOLICITUD_WEB", operador: false },
    { value: "FISICO", operador: true },
    { value: "CONTACT_CENTER_ENTRANTE", operador: true },
    { value: "CONTACT_CENTER_SALIENTE", operador: true },
  ];
  const dataset = useSelector((state) => state.config.dataset);
  const [canSelectCanal, setCanSelectCanal] = React.useState(false);
  const [selectCanal, setSelectCanal] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const errorText = "Seleccione un canal";
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenAlert(false);
    dispatch({
      type: "change/canal",
      payload: { canal: selectCanal.value, operador: selectCanal.operador },
    });
    if (activeStep === maxStep) {
      handleMainNextStep();
    } else {
      handleNextStep();
    }
  };
  const handleOut = () => {
    if (activeStep === maxStep) {
      handleMainNextStep();
    } else {
      handleNextStep();
    }
  };
  if (show && (!dataset.operador || !dataset.token)) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3">
          Ha ocurrido un error, puede continuar sin seleccionar canal
        </Typography>
        <Button
          onClick={() => handleOut()}
          variant="contained"
          type="button"
          color="button"
          sx={{ width: "min-content", alignSelf: "flex-end" }}
        >
          Continuar
        </Button>
      </Box>
    );
  }
  if (!show) {
    return <Box />;
  }
  return (
    <>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={4}
        onSubmit={(e) => handleSubmit(e)}
      >
        {canSelectCanal && (
          <FormControl>
            <SelectInput
              label="Canal"
              select
              value={selectCanal ? selectCanal.value : ""}
              onChange={(e) => {
                setSelectCanal(
                  canales.find((item) => item.value === e.target.value)
                );
              }}
              id="canales"
              name="canales"
              items={canales.map((canal, index) => (
                <MenuItem key={index} value={canal.value}>
                  {canal.value}
                </MenuItem>
              ))}
            />
          </FormControl>
        )}
        <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
        <Button
          disabled={!selectCanal}
          variant="contained"
          type="submit"
          color="button"
          sx={{ width: "min-content", alignSelf: "flex-end" }}
        >
          Continuar
        </Button>
      </Box>
    </>
  );
};

export default StepOneA;
