import * as yup from "yup";
import { getSubstractYearsToToday } from "../utils";

const validationStepOneA = (regexValidationDocument, phoneValidationRegex) =>
  yup.object({
    ...getCedulaValidation(regexValidationDocument),
    ...getTelefonoValidation(phoneValidationRegex),
    pyc: yup.boolean().oneOf([true], "Aceptar términos y condiciones"),
  });

const validationStepOneB = (
  minYearsAgo = 18,
  maxYearsAgo = 150,
  nombre,
  emailOptional,
  minIngresos,
  isPasarela
) => {
  const res = {
    fechaNacimiento: yup
      .date("Ingrese fecha de nacimiento")
      .required("Ingrese fecha de nacimiento")
      .min(
        getSubstractYearsToToday(maxYearsAgo),
        `Debe ser menor de ${maxYearsAgo - minYearsAgo} años`
      )
      .max(
        getSubstractYearsToToday(minYearsAgo),
        `Debe ser mayor de ${minYearsAgo} años`
      ),
    genero: yup.string("Seleccione un género").required("Seleccione un género"),
    ingresos: yup
      .number("Ingresa tus ingresos")
      .required("Ingresa tus ingresos")
      .max(1000000, "La cifra es muy grande"),
    departamento: yup
      .string("Seleccione un departamento")
      .required("Seleccione un departamento"),
    localidad: yup
      .string("Seleccione una localidad")
      .required("Seleccione una localidad"),
    relacionLaboral: yup
      .string("Seleccione relación laboral")
      .required("Seleccione relación laboral"),
  };

  // Add nombre because not is precharged
  if (!nombre) {
    res.nombre = yup.string("Ingrese nombre").required("Ingrese nombre");
    res.apellido = yup.string("Ingrese apellido").required("Ingrese apellido");
  }
  if (!emailOptional) {
    res.email = yup
      .string("Ingrese e-mail")
      .email("Ingrese e-mail")
      .required("Ingrese e-mail");
  }
  if (minIngresos) {
    res.ingresos = res.ingresos.min(
      minIngresos,
      `Debe ser mayor a ${minIngresos}`
    );
  } else {
    res.ingresos = res.ingresos.min(1, "Debe ser mayor a 0");
  }
  if (isPasarela) {
    res.tycGenerales = yup
      .boolean()
      .oneOf([true], "Aceptar términos y condiciones");
  }

  return yup.object(res);
};

export { validationStepOneA, validationStepOneB };

/** Private functions */
function getCedulaValidation(regexValidationDocument) {
  const res = {
    cedula: yup.string("Ingresa cédula").required("Ingresa cédula"),
  };
  if (!regexValidationDocument) return res;

  res.cedula = res.cedula.matches(
    regexValidationDocument,
    "Ingresa una cédula sin puntos, guiones, espacios o letras"
  );
  return res;
}

function getTelefonoValidation(phoneValidationRegex) {
  const res = {
    telefono: yup.string("Ingresa celular").required("Ingresa celular"),
  };
  if (!phoneValidationRegex) return res;

  res.telefono = res.telefono.matches(
    phoneValidationRegex,
    "Ingresa un celular solo con números"
  );
  return res;
}
