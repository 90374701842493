import { Box, Container, CssBaseline, Grid, useTheme } from "@mui/material";
import React from "react";
import {
  //  useDispatch ,
  useSelector,
} from "react-redux";
import Navbar from "./Components/Navbar";
import Side from "./Components/Side";

const Layout = ({ children }) => {
  const theme = useTheme();
  // const dispatch = useDispatch();
  const [warning, setWarning] = React.useState(false);
  const displaySide = useSelector((state) => state.config.displaySide);
  const side = useSelector((state) => state.config.side);
  const displayNavbar = useSelector((state) => state.config.displayNavbar);
  const backgroundUrl = useSelector(
    (state) => state.config.dataset.backgroundUrl
  );
  const sucursal = useSelector(
    (state) => state.config.dataset.operadorData.sucursal
  );
  //////////////////
  // TODO: Improve spinner text, it shows "Aguarda un momento" although the payload is " "
  // const img = new Image();
  // img.onload = function () {
  //   dispatch({ type: "hide/spinner" });
  // };
  // img.src = backgroundUrl;
  // if (backgroundUrl && backgroundUrl !== "") {
  //   dispatch({ type: "show/spinner/text", payload: " " });
  // }
  // if (img.complete) {
  //   img.onload();
  // }
  //////////
  React.useEffect(() => {
    setWarning(sucursal.warning);
  }, [sucursal]);
  return (
    <Box
      // sx={{
      //   filter:
      //     // process.env.REACT_APP_DEMO_FLAVOUR !== "true" &&
      //     // process.env.REACT_APP_GRAYSCALE === "true" &&
      //     window.location.hostname.includes("staging") ? "grayscale(100%)" : "",
      // }}
    >
      {displayNavbar && !warning && <Navbar />}
      <Grid
        sx={{
          backgroundImage:
            backgroundUrl && !warning ? `url(${backgroundUrl})` : "",
          backgroundColor: warning ? "red" : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
        }}
        container
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={displaySide ? 8 : 12}
          style={{
            marginTop: displayNavbar ? "4rem" : "0rem",
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: "1000ms",
            }),
          }}
        >
          <Container maxWidth="md" sx={{ padding: "0" }}>
            <CssBaseline />
            {children}
          </Container>
        </Grid>
        <Side displaySide={displaySide && !warning} side={side && !warning} />
      </Grid>
    </Box>
  );
};

export default Layout;
