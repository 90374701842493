import { Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Busca sin espacios
function customFilter(options, { inputValue }) {
  const matcher = new RegExp(inputValue.trim(), "i");
  return options.filter((option) => matcher.test(option.label));
}

function AutoComplete({
  items, // Esto debe ser un array de objetos con una propiedad 'label' y una propiedad 'value'
  id,
  name,
  label,
  value,
  onChange,
  error,
  disabled,
  fullWidth,
  size,
  required,
}) {
  return (
    <Autocomplete
      autoHighlight
      openOnFocus
      id={id}
      disabled={disabled}
      options={items}
      getOptionLabel={(option) => option.label}
      filterOptions={customFilter} // Utiliza un filtro personalizado aquí
      value={value ? items.find((item) => item.value === value) : null}
      onChange={(event, newValue) => {
        onChange({
          target: {
            name: name,
            value: newValue ? newValue.value : "",
          },
        });
      }}
      PaperComponent={({ children, ...other }) => (
        <Paper
          {...other}
          style={{ maxHeight: "auto" }}
          onTouchStart={(event) => event.stopPropagation()}
        >
          {children}
        </Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          name={name}
          label={label}
          fullWidth={fullWidth}
          error={error}
          size={size}
        />
      )}
    />
  );
}

export default AutoComplete;
