import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import CryptoJS from "crypto-js";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Core from "./components/Steps/Core/Core";
import { theme } from "./config";
import useHandleMaintenance from "./hooks/useHandleMaintenance";
import Layout from "./layout/Layout";
import initXsrfToken from "./services/Api/config/initXsrfToken";
import {
  APPLY_FLAVOR,
  FETCH_DEPTOS,
} from "./services/reducers/configReducer/configReducer";

const App = () => {
  const dataset = useSelector((state) => state.config.dataset);
  const dispatch = useDispatch();

  const getToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("t");
  };

  useHandleMaintenance();

  useEffect(() => {
    if (process.env.REACT_APP_ON_CSRF === "true") initXsrfToken();

    dispatch(FETCH_DEPTOS());
    dispatch(APPLY_FLAVOR());

    if (getToken()) {
      const b64_to_utf8 = (str) => {
        try {
          return decodeURIComponent(Buffer.from(str, "base64"));
        } catch (e) {
          return str;
        }
      };
      const decode = b64_to_utf8(getToken());
      try {
        const bytes = CryptoJS.AES.decrypt(
          decode,
          process.env.REACT_APP_SECRET_TOKEN
        );
        const { id, token, step } = JSON.parse(
          bytes.toString(CryptoJS.enc.Utf8)
        );
        dispatch({
          type: "jump/steps",
          payload: {
            id: id,
            token: token,
            step: step,
          },
        });
      } catch (e) {
        alert("Error al continuar la solicitud");
        console.log(e);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.title = dataset.nombre_flavor;
  }, [dataset.nombre_flavor]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Layout>
          <Core />
        </Layout>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
