import { Step, StepContent, Stepper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Canal from "../../Login/Canal";
import Login from "../../Login/Login";
import StepOver from "../StepOver/StepOver";
import StepFamily from "./StepFamily";
import "./StepOne.css";
import StepOneA from "./StepOneA";
import StepOneB from "./StepOneB";
import StepOneC from "./StepOneC";
import StepOneD from "./StepOneD";
import StepOneE from "./StepOneE";
import StepOneF from "./StepOneF";
import StepSelectAdditionals from "./StepSelectAdditionals";
import StepTyCIntermedio from "./StepTyC_Intermedio";

const StepOne = ({
  handleMainNextStep,
  title,
  stepNumber,
  stepToJump,
  stepsToJump,
}) => {
  const dataset = useSelector((state) => state.config.dataset);
  const [activeStep, setActiveStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const stepOne = useRef(null);

  useEffect(() => {
    if (stepToJump && stepsToJump) {
      if (stepToJump === stepNumber) {
        setActiveStep(stepsToJump);
      }
    }
    // eslint-disable-next-line
  }, [stepToJump, stepsToJump]);

  useEffect(() => {
    setMaxStep(stepOne.current.children.length);
    if (stepOne.current.children.length === 0) {
      handleMainNextStep();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataset.operador === true) {
      setMaxStep(stepOne.current.children.length);
    }
  }, [dataset.operador]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };
  const handleBackStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Box display="flex" flexDirection="column" gap={4} width="100%">
      <StepOver
        stepTitle={title}
        activeStep={activeStep + 1}
        maxStep={maxStep}
      />
      <Stepper
        ref={stepOne}
        activeStep={activeStep}
        orientation="vertical"
        connector={null}
      >
        <Step>
          <StepContent>
            <Login
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        <Step>
          <StepContent>
            <Canal
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        <Step>
          <StepContent>
            <StepOneA
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        <Step>
          <StepContent>
            <StepOneB
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        <Step>
          <StepContent>
            <StepFamily
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        {dataset.deuda && (
          <Step>
            <StepContent>
              <StepOneC
                handleNextStep={handleNextStep}
                handleMainNextStep={handleMainNextStep}
                activeStep={activeStep}
                maxStep={maxStep - 1}
              />
            </StepContent>
          </Step>
        )}
        <Step>
          <StepContent>
            <StepOneD
              handleNextStep={handleNextStep}
              handleMainNextStep={handleMainNextStep}
              handleBackStep={handleBackStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        <Step>
          <StepContent>
            <StepOneE
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        {dataset.adicionales?.list?.length && (
          <Step>
            <StepContent>
              <StepSelectAdditionals
                handleNextStep={handleNextStep}
                handleBackStep={handleBackStep}
                handleMainNextStep={handleMainNextStep}
                activeStep={activeStep}
                maxStep={maxStep - 1}
              />
            </StepContent>
          </Step>
        )}
        <Step>
          <StepContent>
            <StepTyCIntermedio
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
        <Step>
          <StepContent>
            <StepOneF
              handleNextStep={handleNextStep}
              handleBackStep={handleBackStep}
              handleMainNextStep={handleMainNextStep}
              activeStep={activeStep}
              maxStep={maxStep - 1}
            />
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
};

export default StepOne;
