import { Typography, Button, styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
const SinOfertas = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: "2rem",
      fontWeight: 700,
      textAlign: "center",
    },
    body: {
      fontSize: "1rem",
      textAlign: "center",
    },
    icon: {
      fontSize: "5rem",
    },
  };

  const dataset = useSelector((state) => state.config.dataset);
  const getFace = () => {
    return dataset.errorFace;
  };
  const Emoji = styled("div")({
    width: "4rem",
    height: "4rem",
    backgroundImage: `url(./assets/reactions/${getFace()}.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  });
  return (
    <Box sx={styles.container} gap={4}>
      <Typography sx={styles.title}>{dataset.errorTitle}</Typography>
      {dataset.errorFace ? <Emoji /> : <ErrorIcon sx={styles.icon} />}

      <Typography sx={styles.body}>{dataset.errorBody}</Typography>

      {dataset.externalLink && (
        <Button
          variant="contained"
          sx={{ width: "min-content", alignSelf: "center", marginY: "2rem" }}
          onClick={() => window.open(dataset.externalLink, "_self")}
        >
          Inicio
        </Button>
      )}
    </Box>
  );
};

export default SinOfertas;
