import { get } from ".";

const solicitud = {
  getRegexValidationPhone: () =>
    get(`fintech/solicitud/public/regex/validation/phone`),
  getRegexValidationDocument: () =>
    get(`fintech/solicitud/public/regex/validation/document`),
  getIsInMaintenance: () => get(`fintech/solicitud/public/maintenance`),
  getMinIngresoDeclarado: () =>
    get(`fintech/solicitud/public/min/ingreso-declarado`),
};

export default solicitud;
