import React from "react";
import { Box, Typography } from "@mui/material";
import { Delete, UploadFile } from "@mui/icons-material";

const UploadButton = ({
  onChange = (file) => file,
  label,
  maxSize,
  extensions,
}) => {
  const input = React.useRef(null);
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [errorCampo, setErrorCampo] = React.useState(false);
  const [errorExtension, setErrorExtension] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    onChange(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      setError(false);
      setErrorCampo(false);
      setErrorExtension(false);
      if (maxSize !== undefined) {
        if (file.size < maxSize) {
          if (extensions !== undefined) {
            if (
              extensions.includes(
                file.name
                  .toLowerCase()
                  .slice(file.name.lastIndexOf(".") + 1, file.name.length)
              )
            ) {
              setFile(file);
            } else {
              setErrorExtension(true);
              setFile(null);
              input.current.value = "";
            }
          } else {
            setFile(file);
          }
        } else {
          setError(true);
          setFile(null);
          input.current.value = "";
        }
      } else {
        setFile(file);
      }
    } else {
      setErrorCampo(true);
      setFile(null);
      input.current.value = "";
    }
  };

  const handleFileDrag = (e) => {
    setDragging(false);
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    setError(false);
    setErrorExtension(false);
    if (maxSize !== undefined) {
      if (file !== undefined && file.size < maxSize) {
        if (extensions !== undefined) {
          if (
            extensions.includes(
              file.name
                .toLowerCase()
                .slice(file.name.lastIndexOf(".") + 1, file.name.length)
            )
          ) {
            setFile(file);
          } else {
            setErrorExtension(true);
            setFile(null);
            input.current.value = "";
          }
        } else {
          setFile(file);
        }
      } else {
        setError(true);
        setFile(null);
        input.current.value = "";
      }
    } else {
      setFile(file);
    }
  };

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const handleDeteFile = () => {
    setFile(null);
    input.current.value = "";
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
      }}
      gap={1}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
      }}
      onDrop={(e) => {
        e.preventDefault();
        if (file) {
          handleDeteFile();
          handleFileDrag(e);
        } else {
          handleFileDrag(e);
        }
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          background: dragging ? "#d1d1d1" : "#eee",
          width: "100%",
          cursor: "pointer",
          "&:hover": {
            background: "#d1d1d1",
          },
          transition: "all 0.3s ease-in-out",
        }}
        borderRadius={1}
        p={2}
        justifyContent="center"
        alignItems="center"
        onClick={() => {
          input.current.click();
        }}
      >
        <input
          type="file"
          id="file"
          style={{ display: "none", position: "absolute" }}
          ref={input}
          onChange={(e) => handleFile(e)}
        />
        <UploadFile sx={{ fontSize: 40 }} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontWeight={300} fontSize={13}>
            {label}
          </Typography>
          <Typography fontWeight={300} fontSize={10}>
            Arrastra o haz click para subir un archivo ({extensions.join(", ")}
            ).
          </Typography>
        </Box>
      </Box>
      {file && (
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ border: "1px solid #eee", borderRadius: 1, p: 1 }}
        >
          <Typography fontWeight={600} fontSize={13}>
            {file && file.name} | {file && bytesToSize(file.size)}
          </Typography>
          <Delete
            sx={{ fontSize: 20, cursor: "pointer", color: "red" }}
            onClick={() => handleDeteFile()}
          />
        </Box>
      )}
      {/* Error Size */}
      {error && (
        <Box sx={{ color: "red" }}>
          <Typography fontWeight={600} fontSize={13}>
            El archivo seleccionado excede el tamaño permitido de{" "}
            {bytesToSize(maxSize)} o no es de los formatos permitidos.
          </Typography>
        </Box>
      )}
      {errorCampo && (
        <Box sx={{ color: "red" }}>
          <Typography fontWeight={600} fontSize={13}>
            Imagen requerida
          </Typography>
        </Box>
      )}
      {/* Error Size */}
      {errorExtension && (
        <Box sx={{ color: "red" }}>
          <Typography fontWeight={600} fontSize={13}>
            El archivo seleccionado no es un archivo permitido. Los formatos a
            permitir son: {extensions.join(", ")}.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UploadButton;
