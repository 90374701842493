import { Grid, MenuItem, TextField } from "@mui/material";
import React from "react";

function MailAutoCompleteInput({
  value,
  handleChange,
  onBlur,
  onKeyDown,
  label,
  id,
  name,
  error,
}) {
  const options = [
    `@gmail.com`,
    `@outlook.com`,
    `@hotmail.com`,
    `@yahoo.com`,
    `@live.com`,
    `@icloud.com`,
    `@protonmail.com`,
  ];
  const [show, setShow] = React.useState(true);
  const [option, setOption] = React.useState("");

  React.useEffect(() => {
    if (value.includes("@")) {
      if (options.includes("@" + value.split("@")[1]) && option !== "") {
        setShow(true);
      } else {
        setShow(false);
      }
    } else {
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={show ? 8 : 12} lg={show ? 8 : 12}>
        <TextField
          id={id}
          name={name}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          variant="outlined"
          label={label}
          error={error}
          fullWidth
        />
      </Grid>
      <Grid sx={{ display: show ? "block" : "none" }} item xs={4} lg={4}>
        <TextField
          select
          fullWidth
          value={option}
          onChange={(e) => {
            if (e.target.value === "NOTINOPTIONS") {
              setShow(false);
            }
            handleChange(value + e.target.value);
            setOption(e.target.value);
          }}
          error={error}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
          <MenuItem key="NOTINOPTIONS" value="NOTINOPTIONS">
            Otro
          </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}

export default MailAutoCompleteInput;
