import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { validationStepOneA } from "../../../config/validationSchemas";
import { formTriesActions } from "../../../services/reducers/formTriesSlice";
import { stepOneA } from "../../../services/steps.services";
import { clearCedula, errorSetter, showEmotions } from "../../../utils";
import { TextInput } from "../../Inputs";
import Error from "../../Warnings/Error";
import { getIp } from "./hooks";
import useGetRegexValidationDocument from "./hooks/useGetRegexValidationDocument";
import useGetRegexValidationPhone from "./hooks/useGetRegexValidationPhone";
//import OTP from "../../OTP/OTP";

const errors = {
  recaptcha: "Por favor verifica que no eres un robot",
};

const StepOneA = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const dataset = useSelector((state) => state.config.dataset);
  const { isRecaptchaOn } = useSelector((state) => state.formTries);

  const [recaptchaPass, setRecaptchaPass] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorText, setErrorText] = React.useState("Ha ocurrido un error");

  const [callValues, setCallValues] = useState(null);
  const [ipData, setIpData] = React.useState(null);
  const [ipDataErr, setIpDataErr] = React.useState(null);
  const regexValidationDocument = useGetRegexValidationDocument();
  const regexValidationPhone = useGetRegexValidationPhone();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getIp(setIpData, setIpDataErr), []);

  const validation = useFormik({
    initialValues: {
      cedula: "",
      telefono: "",
      pyc: false,
    },
    validationSchema: validationStepOneA(
      regexValidationDocument,
      regexValidationPhone
    ),
    onSubmit: (values) => {
      if (isRecaptchaOn || (dataset.recaptcha && !dataset.operador)) {
        if (recaptchaPass !== null) {
          handleSubmit(values);
        } else {
          setErrorText(errors.recaptcha);
          setOpenAlert(true);
        }
      } else {
        handleSubmit(values);
      }
    },
  });
  const valeVigente = (error) => {
    const err = error.response.data.data
      ? error.response.data.data.returnCode
      : error.response.data.returnCode;
    if (err && err.includes("user_has_another_vale")) {
      dispatch({
        type: "show/help",
        payload: {
          helpTitle: "Ya hay otro vale vigente.",
          helpBody: "",
          showButton: true,
        },
      });
    } else if (err && err.includes("pending_signature")) {
      dispatch({
        type: "show/help",
        payload: {
          helpTitle: "Tenés un vale vigente sin firmar.",
          helpBody: "Debés pasar por Red Pagos a realizar este trámite",
          showButton: true,
        },
      });
    } else if (err && err.includes("existing_request_in_validation")) {
      dispatch({
        type: "show/help",
        payload: {
          helpTitle: "Ya hay otro vale en curso.",
          helpBody: "Un agente se contactará a la brevedad.",
          showButton: false,
        },
      });
    } else if (err && err.includes("found_record")) {
      dispatch({
        type: "show/help",
        payload: {
          helpTitle: "Ha ocurrido un error",
          helpBody: "No se puede continuar tu solicitud.",
          showButton: false,
        },
      });
    } else {
      errorSetter(setOpenAlert, setErrorText, error);
    }
  };

  // callValues by useEffect, is for await ipData before call stepOneA
  useEffect(() => {
    const makeCall = callValues && ipData;
    const awaitingIpData = callValues && !ipData;

    if (makeCall) {
      handleExecuteStepCall(callValues);
    } else if (awaitingIpData && ipDataErr) {
      handleExecuteStepCall(callValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipData, callValues]);

  async function handleSubmit(values) {
    if (values.cedula === "" || values.telefono === "") {
      dispatch(formTriesActions.addTry());
      setErrorText("Complete los datos");
      return setOpenAlert(true);
    }

    setOpenAlert(false);
    dispatch({ type: "show/spinner" });
    setCallValues(values);
  }

  async function handleExecuteStepCall(values) {
    setCallValues(null);
    return stepOneA(
      clearCedula(values.cedula),
      values.telefono,
      dataset.canal,
      dataset.sucursal,
      dataset.token,
      ipData
    )
      .then(async (response) => {
        dispatch(formTriesActions.reset());
        dispatch({
          type: "step/one-a",
          payload: {
            id: response.data.id,
            deudaPrevia: response.data.deudaPrevia
              ? response.data.deudaPrevia
              : null,
            cedula: response.data.documento,
            telefono: values.telefono,
            emailOptional: response.data.emailOptional,
            divisionTercerNivel: response.data.divisionTercerNivel,
            solicitudTipo: response.data.sucursal.solicitudTipo
              ? response.data.sucursal.solicitudTipo
              : "YA_FIRMADA",
            nombre: response.data.nombre ? response.data.nombre : "",
            apellido: response.data.apellido ? response.data.apellido : "",
            segundoApellido: response.data.segundoApellido
              ? response.data.segundoApellido
              : "",
            segundoNombre: response.data.segundoNombre
              ? response.data.segundoNombre
              : "",

            genero: response.data.genero ? response.data.genero : "",
            fechaNacimiento: response.data.fechaNacimiento
              ? response.data.fechaNacimiento
              : "",
            email: response.data.email ? response.data.email : "",
            ingresoDeclarado: response.data.ingresoDeclarado
              ? response.data.ingresoDeclarado
              : "",
            departamento: response.data.departamento
              ? response.data.departamento
              : "",
            localidad: response.data.localidad ? response.data.localidad : "",
            relacionLaboral: response.data.relacionLaboral
              ? response.data.relacionLaboral
              : "",
            direccion: response.data.direccion ? response.data.direccion : "",
            estadoCivil: response.data.estadoCivil
              ? response.data.estadoCivil
              : "",
            nivelEstudio: response.data.nivelEstudioSimple
              ? response.data.nivelEstudioSimple
              : "",

            // Utils
            monedaNacionalName: response.data.monedaNacionalName,

            // Ampliacion / Refinanciacion
            valeHeredadoId: response.data.valeHeredadoId,
            montoHeredado: response.data.montoHeredado,
            generationType: response.data.generationType,
            discountPercentage: response.data.discountPercentage,
          },
        });

        dispatch({ type: "hide/side" });
        dispatch({ type: "hide/main" });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
        if (showEmotions(dataset.sucursal)) {
          let emotion = response.data.emotion;
          if (emotion) {
            if (emotion > 0) {
              setTimeout(() => {
                dispatch({ type: "hide/emotions" });
              }, 4500);
              dispatch({ type: "show/happyFace" });
            }
          }
        }
        return dispatch({ type: "hide/spinner" });
      })
      .catch((error) => {
        dispatch({ type: "hide/spinner" });
        dispatch(formTriesActions.addTry());
        try {
          valeVigente(error);
        } catch (e) {
          errorSetter(setOpenAlert, setErrorText, error);
        }
        if (showEmotions(dataset.sucursal)) {
          try {
            const { returnCode } = error.response.data;
            const { data } = error.response.data;
            if (
              (returnCode && returnCode.includes("Solicitud.no_offers")) ||
              (data.returnCode &&
                data.returnCode.includes("Solicitud.no_offers"))
            ) {
              dispatch({
                type: "show/error",
                payload: {
                  errorTitle: "Sin ofertas",
                  errorBody: dataset.sinOfertasText,
                },
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
  }

  return (
    <>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={4}
        onSubmit={validation.handleSubmit}
      >
        <FormControl>
          <TextInput
            id="cedula"
            name="cedula"
            label="Documento"
            value={validation.values.cedula}
            onChange={(ev) => {
              ev.target.value = ev.target.value.trim();
              validation.handleChange(ev);
            }}
            error={
              validation.touched.cedula && Boolean(validation.errors.cedula)
            }
          />
          <FormHelperText
            error={
              validation.touched.cedula && Boolean(validation.errors.cedula)
            }
          >
            {validation.touched.cedula && validation.errors.cedula}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextInput
            id="telefono"
            name="telefono"
            label="Celular"
            value={validation.values.telefono}
            onChange={(ev) => {
              ev.target.value = ev.target.value.trim();
              validation.handleChange(ev);
            }}
            error={
              validation.touched.telefono && Boolean(validation.errors.telefono)
            }
          />
          <FormHelperText sx={{ color: "red" }}>
            {validation.touched.telefono && validation.errors.telefono}
          </FormHelperText>
        </FormControl>
        <FormGroup required>
          <FormControlLabel
            value={validation.values.pyc}
            checked={validation.values.pyc}
            onChange={validation.handleChange}
            control={
              <Checkbox
                style={{ color: theme.palette.primary.checkColor }}
                id="pyc"
                name="pyc"
                required
              />
            }
            // onClick={handleClickOpen}
            label={
              <Typography fontSize="small" style={{ display: "inline" }}>
                <a
                  style={{ color: "#000000" }}
                  href={dataset.urlTerminos}
                  target="_blank"
                  rel="noreferrer"
                >
                  Acepto términos y condiciones
                </a>
              </Typography>
            }
          />
          <FormHelperText
            error={validation.touched.pyc && Boolean(validation.errors.pyc)}
          >
            {validation.touched.pyc && validation.errors.pyc}
          </FormHelperText>
        </FormGroup>
        {((dataset.recaptcha && !dataset.operador) || isRecaptchaOn) && (
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <ReCAPTCHA
              onChange={(e) => setRecaptchaPass(e)}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
          </Box>
        )}
        <Button
          variant="contained"
          type="submit"
          sx={{ width: "min-content", alignSelf: "flex-end" }}
          color="button"
        >
          Continuar
        </Button>
        <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
      </Box>
    </>
  );
};

export default StepOneA;
