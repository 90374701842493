function is() {
  return window.location.hostname.includes("whitespace");
}

function updateState(state) {
  state.dataset.icon = "TEMPLATE";
  state.dataset.texto_flavor = "Aprobá YA tu crédito, sin papeles";
  state.dataset.nombre_flavor = "Whitespace Fintech";
  state.dataset.apodo_flavor = "Whitespace";
  state.dataset.operador = false;
  state.dataset.recaptcha = false;
  state.dataset.urlTerminos =
    "https://facerecognitionprosbn.blob.core.windows.net/fr-prosbn/tyc.pdf";
  state.dataset.sinOfertasText =
    "Lo siento. En este momento no tenemos una oferta para vos.";
  state.displayNavbar = false;
  state.displayNavbarOptions = false;
  state.displaySide = false;
  state.side = false;
  state.dataset.myAccount = "";
  state.dataset.externalLink = "";
}

export default {
  is,
  updateState,
};
