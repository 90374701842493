import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", marginRight: 2 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

function Loading({ openModal }) {
  const [progress, setProgress] = React.useState(1);
  React.useEffect(() => {
    setProgress(1);
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 99 ? prevProgress + 1 : 99
      );
    }, 550);
    return () => {
      clearInterval(timer);
    };
  }, [openModal]);
  const theme = useTheme();
  return (
    <Dialog
      open={openModal}
      onClose={null}
      aria-labelledby="fr-progress"
      aria-describedby="fr-progress"
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <AiOutlineCloudUpload
            style={{
              fontSize: "5rem",
              color: theme.palette.primary.iconColor,
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          {openModal && <LinearProgressWithLabel value={progress} />}
        </Box>
        <List dense={true}>
          <ListItem>
            <ListItemText primary="Verificando tu identidad, esto puede tardar unos segundos." />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default Loading;
