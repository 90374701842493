import axios from "axios";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDate } from "../../../utils";

const useCore = () => {
  const [stepsToJump, setStepsToJump] = React.useState(null);
  const [stepToJump, setStepToJump] = React.useState(null);
  const dispatch = useDispatch();

  const goToStep = (
    setMainStep,
    step,
    isVigente,
    isExpired,
    estadoSolicitud,
    generationType,
    hasNewMontoCancelacion
  ) => {
    const stepSetter = (main, internal) => {
      setMainStep(main - 1);
      setStepToJump(main);
      setStepsToJump(internal - 1);
    };
    let showEmotion = false;
    if (generationType && hasNewMontoCancelacion) {
      if (
        generationType === "AMPLIACION" ||
        generationType === "REFINANCIACION"
      ) {
        if (step === "STEP_0" || step === "STEP_1") {
          const mainStep = 1;
          const steps = 4;
          return stepSetter(mainStep, steps);
        }
        const mainStep = 1;
        const steps = 5;
        return stepSetter(mainStep, steps);
      }
    }
    if (isVigente || isExpired) {
      if (isExpired) {
        return dispatch({
          type: "show/error",
          payload: {
            errorTitle: "Solicitud expirada",
            errorBody:
              "La solicitud excede los días de expiración desde su fecha de generación. Puedes comenzar una nueva solicitud presionando inicio",
          },
        });
      }
      if (isVigente) {
        if (step === "STEP_0" || step === "STEP_1") {
          const mainStep = 1;
          const steps = 4;
          return stepSetter(mainStep, steps);
        }
        const mainStep = 1;
        const steps = 5;
        return stepSetter(mainStep, steps);
      }
    }
    if (estadoSolicitud) {
      if (
        estadoSolicitud === "DUPLICADA" ||
        estadoSolicitud === "OTORGADO" ||
        estadoSolicitud === "ANULADA"
      ) {
        return dispatch({
          type: "show/error",
          payload: {
            errorTitle: "Ha ocurrido un error",
            errorBody: "No se puede continuar la solicitud",
          },
        });
      }
    }
    if (step === "STEP_0") {
      const mainStep = 1;
      const steps = 4;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_1") {
      const mainStep = 1;
      const steps = 4;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_PRODUCT_FAMILY_CHOOSE") {
      const mainStep = 1;
      const steps = 5;
      showEmotion = true;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_PRODUCT_FAMILY_SELECTED") {
      const mainStep = 1;
      const steps = 5;
      showEmotion = true;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_2") {
      const mainStep = 1;
      const steps = 5;
      showEmotion = true;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_MONEY_DESTINATION") {
      const mainStep = 1;
      const steps = 5;
      showEmotion = true;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_3") {
      const mainStep = 1;
      const steps = 5;
      showEmotion = true;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_4") {
      const mainStep = 1;
      const steps = 5;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_5") {
      const mainStep = 2;
      const steps = 1;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_CONTINUE_OR_CONTACT_CENTER") {
      const mainStep = 2;
      const steps = 2;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_FACE_RECOGNITION") {
      const mainStep = 3;
      const steps = 2;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_DOCUMENT_VALIDATION") {
      const mainStep = 3;
      const steps = 2;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_FACE_RECOGNITION64") {
      const mainStep = 3;
      const steps = 2;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_DOCUMENT_VALIDATION64") {
      const mainStep = 3;
      const steps = 2;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_SECURITY_QUESTIONS") {
      const mainStep = 3;
      const steps = 3;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_MAIL") {
      const mainStep = 3;
      const steps = 1;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_MAIL_CHECK") {
      const mainStep = 3;
      const steps = 1;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_MOBILE") {
      const mainStep = 3;
      const steps = 1;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_ID_VALIDATOR") {
      const mainStep = 3;
      const steps = 3;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_ID_VALIDATOR_CHECK") {
      const mainStep = 3;
      const steps = 3;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_MOBILE_CHECK") {
      const mainStep = 3;
      const steps = 1;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_INCOME_PREDICTOR") {
      const mainStep = 3;
      const steps = 2;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_10") {
      const mainStep = 4;
      const steps = 1;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_DEBT_PAYMENT_1") {
      const mainStep = 4;
      const steps = 1;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_DEBT_PAYMENT_2") {
      const mainStep = 4;
      const steps = 1;
      stepSetter(mainStep, steps);
    }
    if (step === "STEP_REJECTED") {
      const mainStep = 1;
      const steps = 2;
      stepSetter(mainStep, steps);
    }
    if (showEmotion) {
      dispatch({ type: "show/expectationFace" });
    }
    return null;
  };
  const stepSelect = (main, step) => {
    setStepToJump(main);
    setStepsToJump(step);
  };
  const stepNull = () => {
    setStepToJump(null);
    setStepsToJump(null);
  };
  return {
    stepToJump,
    stepsToJump,
    stepNull,
    goToStep,
    stepSelect,
  };
};

const useGetSolicitud = () => {
  const jumpData = useSelector((state) => state.config.jumpData);
  const dataset = useSelector((state) => state.config.dataset);
  const dispatch = useDispatch();

  const getSolicitud = (data) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/fintech/solicitud/${data.id}`,
      headers: {
        Authorization: data.token,
      },
    });
  };
  const getPasarelaData = (callback) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/fintech/solicitud/pasarela/data/${callback}`,
      headers: { "Access-Control-Allow-Origin": "*" },
    });
  };
  const getDataToStep = (token) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/fintech/solicitud/data-to-step/${token}`,
      headers: { "Access-Control-Allow-Origin": "*" },
    });
  };
  const getDepartamentos = () => {
    return axios({
      url: `${process.env.REACT_APP_API_URL}/localidades/paisEstadoProvincia/${process.env.REACT_APP_COUNTRY_CODE}/all`,
      headers: { "Access-Control-Allow-Origin": "*" },
    });
  };
  const getLocalidades = (data) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/localidades/paisLocalidadesCiudades/${process.env.REACT_APP_COUNTRY_CODE}/${data.id}/all`,
      headers: {
        Authorization: data.token,
      },
    });
  };
  const updateStore = (solicitud) => {
    dispatch({
      type: "solicitud/dataset",
      payload: {
        callback: solicitud.callback,
        id: solicitud.id || 0,
        tipoDocumento: solicitud.tipoDocumento ? solicitud.tipoDocumento : 3,
        canal: solicitud.canal,
        token: jumpData.token || null,
        documento: solicitud.documento || "",
        nombre: solicitud.nombre || "",
        apellido: solicitud.apellido || "",
        celular: solicitud.celular || "",
        email: solicitud.email || "",
        fechaMaxima: solicitud.fechaMaxima || "",
        fechaMinima: solicitud.fechaMinima || "",
        montoSolicitado: solicitud.montoSolicitado || "",
        primerVencimiento: solicitud.fechaPrimerCuota || "",
        maxMaximoMonto: solicitud.maxMaximoMonto || "",
        minMinimoMonto: solicitud.minMinimoMonto || "",
        cuotas: solicitud.plazoCuota || "",
        montoCuota: solicitud.montoCuota || "",
        otorgarSiempre: solicitud.otorgarSiempre || false,
        maxMontoSegunTratamiento: solicitud.maxMontoSegunTratamiento || "",
        tyc: dataset.operador
          ? solicitud.terminosYCondicionesBlancoPDF
          : solicitud.terminosYCondicionesPDF,
        tycc: dataset.operador
          ? solicitud.terminosYCondicionesBlancoClientePDF
          : solicitud.terminosYCondicionesPDF,
        idExterno: solicitud.valeExterno || "",
        destinoElegido: solicitud.destinoDinero || "",
        destinos: solicitud.destinoDineroVOLista
          ? solicitud.destinoDineroVOLista
          : solicitud.destinoDineroOpcionesPosibles
          ? solicitud.destinoDineroOpcionesPosibles.split(",")
          : [],
        conOfertas: solicitud.estadoSolicitud ? solicitud.estadoSolicitud : "",
        emailOptional: solicitud.emailOptional || false,
        solicitudTipo: solicitud.sucursal?.solicitudTipo || "REQUIERE_FIRMA",
        localidad: solicitud.localidad || "",
        departamento: solicitud.departamento || "",
        showMobileValidation: solicitud.productoFinanciero
          ? solicitud.productoFinanciero.mobile !== null &&
            solicitud.productoFinanciero.mobile !== undefined
            ? solicitud.productoFinanciero.mobile
            : true
          : true,
        showEmailValidation: solicitud.productoFinanciero
          ? solicitud.productoFinanciero.email !== null &&
            solicitud.productoFinanciero.email !== undefined
            ? solicitud.productoFinanciero.email
            : true
          : true,
        showFaceRecognition: solicitud.productoFinanciero
          ? solicitud.productoFinanciero.faceRecognition !== null &&
            solicitud.productoFinanciero.faceRecognition !== undefined
            ? solicitud.productoFinanciero.faceRecognition
            : true
          : true,
        showIdValidator: solicitud.productoFinanciero
          ? solicitud.productoFinanciero.securityQuestions !== null &&
            solicitud.productoFinanciero.securityQuestions !== undefined
            ? solicitud.productoFinanciero.securityQuestions
            : true
          : true,
        segundoNombre: solicitud.segundoNombre || "",
        segundoApellido: solicitud.segundoApellido || "",
        sucursalId: solicitud.sucursal ? solicitud.sucursal.id : 1,
        sucursal: solicitud.sucursal,
        mailOperador: "Sin mail de operador",
        slot: solicitud.prodFinanFlia ? solicitud.prodFinanFlia.slot : null,
        estadoCivil: solicitud.estadoCivil || "",
        nivelEstudio: solicitud.nivelEstudioSimple || "",
        relacionLaboral: solicitud.relacionLaboral || "",
        fechaNacimiento:
          moment(handleDate(solicitud.fechaNacimiento)).utcOffset(0) || "",
        ingresoDeclarado: solicitud.ingresoDeclarado || "",
        genero: solicitud.genero || null,
        destinoDineroLabel: solicitud.destinoDineroLabel || null,

        // Utils
        monedaNacionalName: solicitud.monedaNacionalName,

        // Ampliacion / Refinanciacion
        valeHeredadoId: solicitud.valeHeredadoId,
        montoHeredado: solicitud.montoHeredado,
        generationType: solicitud.generationType,
        discountPercentage: solicitud.discountPercentage,

        adicionales: {
          list: solicitud.productoFinancieroAdicionalLista,
          selectedVerified: solicitud.solicitudAdicionalLista?.map(
            (o) => o.productoFinancieroAdicional
          ),
          montoCuotaConAdicionales: solicitud.montoCuotaConAdicionales,
          maxToSelect: solicitud.productoFinanciero?.cantMaxSeleccionable,
          minToSelect: solicitud.productoFinanciero?.cantMinSeleccionable,
          autoselect: solicitud.productoFinanciero?.auto_seleccionar_yn,
        },

        // Pasarela
        montoPasarela: solicitud.montoPasarela,
        pasarelaWebhookUrl: solicitud.pasarelaWebhookUrl,
        pasarelaName: solicitud.pasarelaName,
        pasarelaSecondsToBackOnError: solicitud.pasarelaSecondsToBackOnError,
        pasarelaSecondsToBackOnSuccess:
          solicitud.pasarelaSecondsToBackOnSuccess,
      },
    });
  };
  return {
    getDataToStep,
    getSolicitud,
    getPasarelaData,
    getLocalidades,
    getDepartamentos,
    updateStore,
  };
};

export { useCore, useGetSolicitud };
