import { Box, Button, FormControl, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { TextInput } from "../../Inputs";

const StepOneC = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dataset = useSelector((state) => state.config.dataset);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeStep === maxStep) {
      handleMainNextStep();
    } else {
      handleNextStep();
    }
  };
  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={4}
      onSubmit={(e) => handleSubmit(e)}
    >
      <Typography variant="h4">Su deuda es de {dataset.montoDeuda}</Typography>
      <FormControl required sx={{ width: "100%" }}>
        <TextInput label="Monto cancelado" id="monto" name="monto" />
      </FormControl>
      <FormControl required sx={{ width: "100%" }}>
        <TextInput
          label="Comprobante de pago"
          id="comprobante"
          name="comprobante"
          required
        />
      </FormControl>
      <Button
        variant="contained"
        type="submit"
        color="button"
        sx={{ width: "min-content", alignSelf: "flex-end" }}
      >
        Continuar
      </Button>
    </Box>
  );
};

export default StepOneC;
