import { useEffect, useState } from "react";
import apiSolicitud from "../../../../services/Api/solicitud";

export default function useGetRegexValidationDocument() {
  const [documentRegex, setDocumentRegex] = useState(null);

  useEffect(() => {
    getDocumentRegex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getDocumentRegex() {
    try {
      const response = await apiSolicitud.getRegexValidationDocument();
      if (response.data) setDocumentRegex(response.data);
      else setDocumentRegex(null);
    } catch (err) {
      console.log(err);
      setDocumentRegex(null);
    }
  }

  return documentRegex;
}
