function is() {
  return window.location.hostname.includes("tiamarta");
}

function updateState(state) {
  state.dataset.flavor = "MARTA";
  state.dataset.icon = "MARTA";
  state.dataset.texto_flavor = "Aprobá YA tu crédito, sin papeles";
  state.dataset.operador = false;
  state.dataset.nombre_flavor = "Tia Marta";
  state.dataset.apodo_flavor = "Tia Marta";
  state.dataset.recaptcha = false;
  state.dataset.urlTerminos =
    "https://facerecognitionprosbn.blob.core.windows.net/fr-prosbn/tyc.pdf";
  state.dataset.sinOfertasText =
    "Lo siento, en este momento no tenemos una oferta para vos.";
  state.displayNavbar = false;
  state.displayNavbarOptions = false;
  state.displaySide = false;
}

export default {
  is,
  updateState,
};
