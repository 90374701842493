import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import es from "date-fns/locale/es";
import React from "react";
import DatePickerExternal from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/green.css";
import "react-multi-date-picker/styles/colors/red.css";
import "react-multi-date-picker/styles/colors/purple.css";
import "react-multi-date-picker/styles/colors/yellow.css";
import { useTheme } from "@emotion/react";
import moment from "moment";

function getDefaultYear() {
  const currentYear = new Date().getFullYear();
  const date = new Date();
  date.setFullYear(currentYear - 18);
  return date;
}

function DateInput({
  label,
  max,
  min,
  error,
  refference,
  value,
  onChange,
  disabled,
  required,
  showTwoMonths,
  format,
}) {
  const theme = useTheme();

  if (showTwoMonths) {
    return (
      <DatePickerExternal
        className={`${theme.multiDateColor}`}
        title={label}
        weekDays={["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"]}
        months={[
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ]}
        numberOfMonths={2}
        value={value}
        render={
          <TextField
            fullWidth
            label={label}
            format
            value={value}
            InputProps={{ readOnly: true }}
            required={required}
          />
        }
        minDate={moment(min).format("YYYY-MM-DD")}
        maxDate={moment(max).format("YYYY-MM-DD")}
        onChange={(e) => onChange(moment(new Date(e)).format("YYYY-MM-DD"))}
        format={format}
      />
    );
  }
  return (
    <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        inputFormat="dd/MM/yyyy"
        maxDate={max}
        disabled={disabled}
        minDate={min}
        defaultCalendarMonth={getDefaultYear()}
        value={value === "" ? null : value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            required={required}
            autoComplete="off"
            ref={refference}
            {...params}
            error={error}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default DateInput;
