import { useEffect, useState } from "react";
import apiSolicitud from "../../../../services/Api/solicitud";

export default function useGetMinIngresoDeclarado(defaultValue) {
  const [res, setRes] = useState(defaultValue);

  useEffect(() => {
    getMinIngresoDeclarado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getMinIngresoDeclarado() {
    try {
      const response = await apiSolicitud.getMinIngresoDeclarado();
      if (response.data) setRes(response.data);
      else setRes(null);
    } catch (err) {
      console.log(err);
      setRes(null);
    }
  }

  return res;
}
