import { Box, Button, FormControl, MenuItem } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepFamily, stepSelectFamily } from "../../../services/steps.services";
import { errorSetter } from "../../../utils";
import { SelectInput } from "../../Inputs";
import Error from "../../Warnings/Error";

const StepOneE = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorText, setErrorText] = React.useState("Ha ocurrido un error");
  const [family, setFamily] = React.useState("");
  const [allFamilies, setAllFamilies] = React.useState([]);
  const [familiesCalled, setFamiliesCalled] = React.useState(false);
  const [familyOptions, setFamilyOptions] = React.useState({
    slot: null,
    tipoDeCalculo: null,
  });
  const dataset = useSelector((state) => state.config.dataset);
  React.useEffect(() => {
    dispatch({
      type: "show/spinner/text",
      payload: "Buscando las mejores ofertas para vos...",
    });

    stepFamily(
      dataset.id,
      dataset.cedula,
      dataset.telefono,
      dataset.canal,
      dataset.sucursal,
      dataset.token
    )
      .then((response) => {
        if (response.data.prodFinanFliaList.length === 0) {
          dispatch({
            type: "show/error",
            payload: {
              errorTitle: "Sin ofertas",
              errorBody: dataset.sinOfertasText,
            },
          });
        } else if (response.data.prodFinanFliaList.length > 1) {
          setAllFamilies(response.data.prodFinanFliaList);
          setFamiliesCalled(true);
          dispatch({ type: "hide/emotions" });
          dispatch({ type: "hide/spinner" });
        } else {
          setAllFamilies(response.data.prodFinanFliaList);
          setFamiliesCalled(true);
        }
      })
      .catch((err) => {
        dispatch({ type: "show/spinner" });
        setOpenAlert(true);
        errorSetter(setOpenAlert, setErrorText, err);
        console.log(err);

        valeVigente(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valeVigente = (error) => {
    const err = error.response.data.data
      ? error.response.data.data.returnCode
      : error.response.data.returnCode;

    if (err?.includes("concurrent_issue")) {
      dispatch({
        type: "show/help",
        payload: {
          helpTitle: "Ya hay otro vale en curso.",
          helpBody: "Un agente se contactará a la brevedad.",
          showButton: false,
        },
      });
      return true;
    }
  };

  React.useEffect(() => {
    if (familiesCalled) {
      if (allFamilies.length === 1) {
        dispatch({
          type: "show/spinner/text",
          payload: "Buscando las mejores ofertas para vos...",
        });
        stepSelectFamily(
          dataset.id,
          dataset.cedula,
          dataset.telefono,
          dataset.canal,
          dataset.sucursal,
          dataset.token,
          allFamilies[0].id
        )
          .then((response) => {
            if (
              response.data.ofertasFormateadas &&
              response.data.ofertasFormateadas.length > 0
            ) {
              dispatch({
                type: "step/family",
                payload: {
                  familia: allFamilies[0].id,
                  ofertas: response.data.ofertasFormateadas,
                  monedaNacional: response.data.monedaNacionalNombre,
                  slot: allFamilies[0].slot ? allFamilies[0].slot : null,
                  tipoDeCalculo: allFamilies[0].tipoDeCalculo
                    ? allFamilies[0].tipoDeCalculo
                    : null,
                },
              });
              if (activeStep === maxStep) {
                handleMainNextStep();
              } else {
                handleNextStep();
              }
            } else {
              dispatch({
                type: "show/error",
                payload: {
                  errorTitle: "Sin ofertas",
                  errorBody: dataset.sinOfertasText,
                },
              });
            }
            dispatch({ type: "hide/emotions" });
            dispatch({ type: "hide/spinner" });
          })
          .catch((err) => {
            if (stepSelectFamilyErrDispatch(err, dispatch)) {
            } else {
              console.log(err);
              errorSetter(setOpenAlert, setErrorText, err);
            }
            dispatch({ type: "hide/emotions" });
            dispatch({ type: "hide/spinner" });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFamilies, familiesCalled]);

  const handleSubmit = async (e, selectedFamily) => {
    e.preventDefault();
    setOpenAlert(false);
    dispatch({ type: "show/spinner" });
    stepSelectFamily(
      dataset.id,
      dataset.cedula,
      dataset.telefono,
      dataset.canal,
      dataset.sucursal,
      dataset.token,
      selectedFamily
    )
      .then((response) => {
        dispatch({ type: "hide/spinner" });
        if (
          response.data.ofertasFormateadas &&
          response.data.ofertasFormateadas.length > 0
        ) {
          dispatch({
            type: "step/family",
            payload: {
              familia: family,
              ofertas: response.data.ofertasFormateadas,
              monedaNacional: response.data.monedaNacionalNombre,
              slot: familyOptions.slot,
              tipoDeCalculo: familyOptions.tipoDeCalculo,
            },
          });
          dispatch({
						type: "step/family/more-than-one",
					});
          if (activeStep === maxStep) {
            handleMainNextStep();
          } else {
            handleNextStep();
          }
        } else {
          setOpenAlert(true);
          setErrorText(dataset.sinOfertasText);
        }
      })
      .catch((err) => {
        if (stepSelectFamilyErrDispatch(err)) {
        } else {
          console.log(err);
          errorSetter(setOpenAlert, setErrorText, err);
        }

        dispatch({ type: "hide/spinner" });
        dispatch({ type: "hide/emotions" });
      });
  };
  if (familiesCalled && allFamilies.length === 1) {
    return (
      <Box>
        <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
        {openAlert && dataset.externalLink && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              sx={{
                width: "min-content",
                alignSelf: "center",
                marginY: "2rem",
              }}
              color="button"
              onClick={() => window.open(dataset.externalLink, "_self")}
            >
              Inicio
            </Button>
          </Box>
        )}
      </Box>
    );
  }
  if (familiesCalled && allFamilies.length === 0) {
    setOpenAlert(true);
    setErrorText("No hay familias disponibles");
    return (
      <Box>
        <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
      </Box>
    );
  }
  return familiesCalled && allFamilies.length > 1 ? (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={4}
      onSubmit={(e) => handleSubmit(e, family)}
    >
      <FormControl required sx={{ width: "100%" }}>
        <SelectInput
          id="familia"
          name="familia"
          label="Escenario"
          value={family}
          onChange={(e) => {
            setFamily(e.target.value);
          }}
          items={allFamilies.map((item, index) => (
            <MenuItem
              key={index}
              value={item.id}
              onClick={() =>
                setFamilyOptions({
                  slot: item.slot ? item.slot : null,
                  tipoDeCalculo: item.tipoDeCalculo ? item.tipoDeCalculo : null,
                })
              }
            >
              {item.nombre}
            </MenuItem>
          ))}
        />
      </FormControl>
      <Box sx={{ display: "flex", justifyContent: "end" }} gap={4}>
        <Button color="button" variant="contained" type="submit">
          Continuar
        </Button>
      </Box>
      <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
    </Box>
  ) : (
    <Box component="form" display="flex" flexDirection="column" gap={4}>
      <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
    </Box>
  );
};

export default StepOneE;

export const stepSelectFamilyErrDispatch = (error, dispatch) => {
  let errDispatched = true;
  const err = error.response.data.data
    ? error.response.data.data.returnCode
    : error.response.data.returnCode;

  if (err && err.includes("Refinancia.not_allowed")) {
    dispatch({
      type: "show/help",
      payload: {
        helpTitle: "Refinanciación no disponible.",
        helpBody:
          "Lo sentimos, en estos momentos no podemos ofrecerte una refinanciación.",
        showButton: false,
      },
    });
  } else if (err && err.includes("Ampliacion.not_allowed")) {
    dispatch({
      type: "show/help",
      payload: {
        helpTitle: "Ampliación no disponible.",
        helpBody:
          "Lo sentimos, en estos momentos no podemos ofrecerte una ampliación.",
        showButton: false,
      },
    });
  } else {
    errDispatched = false;
  }

  return errDispatched;
};
