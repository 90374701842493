import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  Fade,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useRef, useState } from "react";
import Webcam from "react-webcam";

const CameraContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(0),
  width: "100%",
}));

const CameraContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
}));

const CameraModal = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
}));

const CameraInstructions = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  left: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  zIndex: 1,
}));

const CameraOptions = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  left: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  zIndex: 2,
}));

const iconSize = 40;
const ButtonDevices = ({ deviceList, setDevice, device, disabled }) => {
  const handleClick = (event) => {
    if (deviceList.length > 0) {
      if (deviceList.length === deviceList.indexOf(device) + 1) {
        return setDevice(deviceList[0]);
      }
      const deviceToSet = deviceList[deviceList.indexOf(device) + 1];
      return setDevice(deviceToSet);
    }
    return setDevice({ deviceId: "No device found" });
  };
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="secondary"
        disabled={disabled}
      >
        <CameraswitchIcon sx={{ fontSize: iconSize }} />
      </IconButton>
    </>
  );
};

const Camera = ({
  onCapture = (file) => file,
  captures = 1,
  title = "Camera",
  device,
  deviceList,
  setDevice,
  takeAgain,
  convertToBlob = false,
  icon = <CameraAltIcon />,
  setStatus = () => {},
}) => {
  const tiempoDeEspera = 8500;
  const intervaloCaptura = 1000;
  const [currentCapture, setCurrentCapture] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [showCapture, setShowCapture] = React.useState(false);
  const [capturing, setCapturing] = React.useState(false);
  const [error, setError] = useState(false);
  const [showText, setShowText] = useState(true);
  const webcamRef = React.useRef(null);
  React.useEffect(() => {
    setCurrentCapture(null);
  }, [takeAgain]);
  let totalCaptures = [];

  const dataURItoBlob = (dataURI) => {
    const byteString = window.atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };
  const videoMode = () => {
    setCapturing(true);
    setTimeout(() => {
      setOpen(false);
      setCapturing(false);
      setShowCapture(true);
    }, tiempoDeEspera);
  };

  const CameraFunctions = {
    capture: React.useCallback(() => {
      if (captures > 1) {
        const snap = setInterval(() => {
          if (webcamRef.current) {
            totalCaptures.push(webcamRef.current.getScreenshot());
          }
        }, intervaloCaptura);
        setTimeout(() => {
          clearInterval(snap);
          setCurrentCapture(totalCaptures);
          if (convertToBlob) {
            let converted = [];
            totalCaptures.forEach((item) => {
              try {
                converted.push(dataURItoBlob(item));
              } catch (error) {
                converted.push(
                  dataURItoBlob(webcamRef.current.getScreenshot())
                );
              }
            });
            onCapture(converted);
          } else {
            onCapture(totalCaptures);
          }
        }, intervaloCaptura * captures);
      } else {
        setCapturing(true);
        setTimeout(() => {
          setCurrentCapture([webcamRef.current.getScreenshot()]);
          setShowCapture(true);
          setOpen(false);
          setCapturing(false);
        }, 500);

        if (convertToBlob) {
          try {
            onCapture([dataURItoBlob(webcamRef.current.getScreenshot())]);
          } catch (error) {
            onCapture([webcamRef.current.getScreenshot()]);
          }
        } else {
          onCapture([webcamRef.current.getScreenshot()]);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [captures, convertToBlob, onCapture]),
    reset: () => {
      setCurrentCapture(null);
    },
  };

  const mounted = useRef(false);
  React.useEffect(() => {
    let existFiles = false;

    try {
      existFiles = currentCapture[0].length;
      for (const photo of currentCapture) {
        existFiles = photo.length;
        if (!existFiles) {
          break;
        }
      }
    } catch (error) {}

    if (mounted.current) {
      setError(!existFiles);
      setStatus(existFiles);
    }
    mounted.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCapture]);

  React.useEffect(() => {
    if (open) {
      setShowText(true);
    }
  }, [open]);

  return (
    <>
      <CameraModal open={open} onClose={() => null}>
        <CameraOptions>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            style={{
              width: "100%",
              backdropFilter: "blur(5px)",
              backgroundColor: "rgba(255,255,255,0.1)",
              margin: "1rem 5rem",
              borderRadius: "0.5rem",
            }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              disabled={capturing}
              color="secondary"
            >
              <CloseIcon sx={{ fontSize: iconSize }} />
            </IconButton>
            {capturing ? (
              <CircularProgress aria-describedby="capturing" size={20} />
            ) : (
              <IconButton
                onClick={() =>
                  captures > 1 ? videoMode() : CameraFunctions.capture()
                }
                color="primary"
                disabled={capturing}
              >
                <CameraAltIcon sx={{ fontSize: iconSize }} />
              </IconButton>
            )}
            <ButtonDevices
              device={device}
              deviceList={deviceList}
              color="button"
              setDevice={setDevice}
              disabled={capturing}
            />
          </Stack>
        </CameraOptions>
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <Webcam
            audio={false}
            screenshotFormat="image/jpg"
            videoConstraints={{
              deviceId: device.deviceId,
              width: window.innerWidth > 550 ? "100%" : 750,
            }}
            ref={webcamRef}
            width="500rem"
            // minScreenshotHeight={760}
            // minScreenshotWidth={1080}
            screenshotQuality={1}
          />

          <CameraInstructions>
            <Stack
              direction="row"
              alignItems="center"
              justifyItems="center"
              height={
                captures > 1
                  ? { xs: "24rem", md: "14rem", lg: "14rem" }
                  : { xs: "26rem", md: "12rem", lg: "12rem" }
              }
              width={
                captures > 1
                  ? { xs: "14rem", md: "10rem", lg: "10rem" }
                  : "16rem"
              }
              style={{
                marginBottom: captures > 1 ? "6rem" : "8rem",
                borderRadius: captures > 1 ? "50%" : "0.5rem",
                boxShadow: "0px 0px 0px 4px rgba(0,0,0,0.3)",
              }}
            >
              <Fade in={showText} timeout={3000}>
                <Box
                  sx={{
                    opacity: 0.7,
                    fontSize: "1.5rem",
                    margin: captures > 1 ? "4rem auto" : "3rem auto",
                  }}
                >
                  <Typography textAlign="center" variant="h5">
                    {" "}
                    {captures > 1 ? (
                      <>
                        Centra
                        <br /> tu <br />
                        rostro
                      </>
                    ) : (
                      <>
                        Centra
                        <br /> tu <br />
                        documento
                      </>
                    )}
                  </Typography>
                </Box>
              </Fade>
            </Stack>
          </CameraInstructions>
        </Box>
      </CameraModal>
      <CameraContainer
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setOpen(true);
          captures > 1 &&
            setTimeout(() => {
              CameraFunctions.capture();
            }, 3000);
        }}
        elevation={3}
      >
        <CameraContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {currentCapture && showCapture ? (
              <>
                <img
                  src={currentCapture[0]}
                  alt="capture"
                  style={{ width: "100%", borderRadius: "0.3rem" }}
                />
                {error && (
                  <Alert sx={{ width: "100%" }} severity="error">
                    Error al tomar foto.
                  </Alert>
                )}
              </>
            ) : (
              icon
            )}
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              {title}
            </Typography>
          </Stack>
        </CameraContent>
      </CameraContainer>
    </>
  );
};

export default Camera;
