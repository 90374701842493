import React from "react";
import { Alert } from "@mui/material";

function Error({ show, text, setShow }) {
  return (
    <div>
      {show && (
        <Alert
          onClose={() => setShow(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {text}
        </Alert>
      )}
    </div>
  );
}

export default Error;
