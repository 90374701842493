import { useEffect } from "react";
import { useDispatch } from "react-redux";
import apiSolicitud from "../services/Api/solicitud";

export default async function useHandleMaintenance() {
  const dispatch = useDispatch();

  useEffect(() => {
    handleMaintenance();
    // eslint-disable-next-line
  }, []);

  async function handleMaintenance() {
    try {
      let response = await apiSolicitud.getIsInMaintenance();
      if (response.data || response.status !== 200) {
        dispatch({
          type: "show/error",
          payload: {
            errorTitle: "En mantenimiento, intente más tarde",
            errorBody:
              "Disculpe las molestias, estamos realizando tareas de mantenimiento en el sistema.",
          },
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: "show/error",
        payload: {
          errorTitle: "En mantenimiento, intente más tarde",
          errorBody:
            "Disculpe las molestias, estamos realizando tareas de mantenimiento en el sistema.",
        },
      });
    }
  }
}
