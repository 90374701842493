import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const StepOneE = ({
  handleNextStep,
  handleBackStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dataset = useSelector((state) => state.config.dataset);
  React.useEffect(() => {
    if (!dataset.remoteSignature) {
      if (activeStep === maxStep) {
        handleMainNextStep();
      } else {
        handleNextStep();
      }
    }
    //eslint-disable-next-line
  }, []);
  if (!dataset.remoteSignature) {
    return null;
  }
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        justifyItems="center"
        alignItems="center"
        sx={{
          backgroundColor: "background.default",
          borderRadius: "1rem",
          padding: { xs: "1rem", sm: "2rem", md: "2rem", lg: "3rem" },
        }}
      >
        <img
          src={`./assets/images/${dataset.icon.toLowerCase()}_main_icon.webp`}
          srcSet={`./assets/images/${dataset.icon.toLowerCase()}_main_icon.webp`}
          alt={dataset.icon}
          style={{ width: "200px" }}
          loading="lazy"
        />
        <Typography>
          Te llegara un link a tu celular para poder continuar con la solicitud.
        </Typography>
      </Box>
    </Box>
  );
};

export default StepOneE;
